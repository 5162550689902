<template>
  <b-card title="Larva.io cloud management" sub-title="Allow device management via larva.io cloud" class="mb-4">
    <div v-if="settings">
      <b-form-checkbox v-model="settings.envVariables" :value="true" :unchecked-value="false">Bench environment variables</b-form-checkbox>
    </div>
    <div class="text-center mt-2">
      <b-btn id="save_btn" variant="success" title="Save ENV variables" :disabled="!settings" @click="setSettings()"> Save </b-btn>
    </div>
  </b-card>
</template>

<script>
// TODO: nice editor
export default {
  inject: ['$larva'],
  data() {
    return {
      settings: null,
    };
  },
  mounted() {
    this.getSettings();
  },
  methods: {
    async getSettings() {
      try {
        const res = await this.$larva.request('iot-2/cmd/getHiveFetchSettings/fmt/json', null);
        this.settings = res;
      } catch (err) {
        this.err(err);
      }
    },
    async setSettings() {
      try {
        await this.$larva.request('iot-2/cmd/setHiveFetchSettings/fmt/json', this.settings);
        this.success('Saved');
      } catch (err) {
        this.err(err);
      }
    },
    err(err) {
      this.$notify({
        title: 'Error',
        text: err.message || 'Unknown error',
        type: 'error',
      });
    },
    success(message) {
      this.$notify({
        title: 'Done',
        text: message,
        type: 'success',
      });
    },
  },
};
</script>

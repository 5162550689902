
import Component from 'vue-class-component';
import Vue from 'vue';
import * as Cookie from 'tiny-cookie';
import * as _ from 'lodash';

@Component({
  inject: ['$larva'],
})
export default class extends Vue {
  units: { value: string | null; text: string }[] = [{ value: null, text: '-- System --' }];
  unit: string | null = null;
  input: {
    username: string;
    password: string; // ,
    // authflow: 'REFRESH_TOKEN_AUTH'
  } = { username: '', password: '' };

  created() {
    const token = Cookie.get('token');
    if (token) {
      Cookie.remove('token');
      Cookie.remove('unit');
    }
    this.getUnits();
  }
  connectionOpen() {
    this.getUnits();
  }
  err(err) {
    this.$notify({
      title: 'Error',
      text: err.message || 'Unknown error',
      type: 'error',
    });
  }
  getUnits() {
    this.$larva
      .reconnect()
      .then(() => this.$larva.request('iot-2/cmd/getUnits/fmt/json'))
      .then((data) => {
        this.units = _.map(data.units, (unit) => ({ value: unit.id, text: unit.name }));
        this.units.unshift({ value: null, text: '-- System --' });
      })
      .catch(this.err);
  }
  login() {
    if (this.unit) {
      this.$larva.setUnit(this.unit);
    } else {
      this.$larva.unsetUnit();
    }
    Cookie.remove('token');
    Cookie.remove('unit');
    this.$larva
      .reconnect()
      .then(() => this.$larva.request('iot-2/cmd/login/fmt/json', this.input))
      .then((data) => {
        if (data.auth && data.access_token) {
          // TODO: { expires: date }
          Cookie.set('token', data.access_token);
          if (this.unit) {
            Cookie.set('unit', this.unit);
          }
          return this.$larva.reconnect();
        }
        throw new Error('Login Failed');
      })
      .then(() => this.$router.replace({ name: 'app' }))
      .catch(this.err);
  }
  get submitDisabled() {
    return this.input.username !== '' && this.input.password !== '';
  }
}


import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  inject: ['$larva'],
  props: {
    connectionId: {
      type: String,
    },
  },
})
export default class extends Vue {
  declare connectionId: string;

  public value: number | null = null;

  public mounted() {
    const { connectionId: id } = this;
    this.$larva
      .request('iot-2/cmd/getTechnicalConnectionStatus/fmt/json', { id })
      .then((res) => {
        this.value = res;
      })
      .catch(this.err);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private err(err: any) {
    this.$notify({
      title: 'Error',
      text: err.message || 'Unknown error',
      type: 'error',
    });
  }
}

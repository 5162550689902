<template>
  <div class="row">
    <div class="col-12">
      <b-card title="Alarms">
        <b-table hover :fields="alarmFields" :items="alarms" show-empty>
          <template #empty> No alarms found </template>
          <template v-slot:cell(activatedAt)="{ item }">
            <span>{{ item.activatedAt | moment('lll') }}</span>
          </template>
          <template v-slot:cell(name)="{ item }">
            <b-badge variant="dark" :id="item.id + '-popover-target'"> {{ item.name }} <fa-icon v-if="item.description" icon="question-circle" /> </b-badge>
            <b-popover v-if="item.description" :target="item.id + '-popover-target'" triggers="hover" container="alarms-card">
              <pre>{{ item.description }}</pre>
            </b-popover>
          </template>
          <template v-slot:cell(state)="{ item }">
            <b-badge
              :id="item.id + '-tooltip-target'"
              :class="{
                'text-uppercase': true,
                orange: item.state === 'active' && item.priority === 1,
                yellow: item.state === 'active' && item.priority === 0,
                red: item.state === 'active' && item.priority === 2,
              }"
              v-b-tooltip.hover
            >
              {{ item.state }}
            </b-badge>
            <b-tooltip :target="item.id + '-tooltip-target'" placement="top">
              <template v-if="item.activatedAt">{{ activatedAtTooltip(item) }}</template>
            </b-tooltip>
          </template>
          <template v-slot:cell(priority)="{ item }">
            <b-badge variant="dark">
              {{ item.priority === 2 ? 'high' : item.priority === 1 ? 'medium' : 'low' }}
            </b-badge>
          </template>
          <template v-slot:cell(description)="{ item }">
            {{ item.description }}
          </template>
          <template v-slot:cell(unitId)="{ item }">
            <UnitNameLink v-if="item.unitId" :unitId="item.unitId" />
          </template>
          <template v-slot:cell(class)="{ item }">
            <span>
              {{ item.class }}
            </span>
          </template>
          <template v-slot:cell(source)="{ item }">
            <span>
              {{ item.source }}
            </span>
          </template>
          <template v-slot:cell(data)="{ item }">
            <code style="font-size: 0.7em; line-height: 1.3em">{{ item.data }}</code>
          </template>
        </b-table>
        <b-pagination :total-rows="alarms ? alarms.length : 0" :per-page="limit" small @change="getAlarms" />
      </b-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.orange {
  background-color: var(--orange) !important;
}
.yellow {
  background-color: var(--yellow) !important;
}
.red {
  background-color: var(--red) !important;
}
</style>

<script>
import moment from 'moment';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import UnitNameLink from '../components/UnitNameLink.vue';

library.add(faQuestionCircle);

export default {
  inject: ['$larva'],
  components: {
    UnitNameLink,
  },
  data() {
    return {
      alarms: [],
      limit: 50,
      alarmFields: [
        {
          key: 'name',
          sortable: false,
        },
        {
          key: 'state',
          sortable: false,
        },
        {
          key: 'class',
          sortable: true,
        },
        {
          key: 'source',
          sortable: true,
        },
        {
          key: 'priority',
          sortable: true,
        },
        {
          key: 'data',
          sortable: false,
        },
        {
          key: 'unitId',
          label: 'Unit',
          sortable: true,
        },
        {
          key: 'technicalConnectionId',
          label: 'Technical Connection',
          sortable: true,
        },
        {
          key: 'technicalDataPointId',
          label: 'Technical Datapoint',
          sortable: true,
        },
        {
          key: 'unitId',
          label: 'Unit',
          sortable: true,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.getAlarms();
  },
  methods: {
    success(title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    err(err) {
      this.$notify({
        title: 'Error',
        text: err.message || 'Unknown error',
        type: 'error',
      });
    },
    async getAlarms(page) {
      const currentpage = (page || 1) - 1;
      const offset = currentpage * this.limit;
      const { limit } = this;
      try {
        const rsp = await this.$larva.request('iot-2/cmd/getAlarms/fmt/json', { offset, limit });
        this.alarms = rsp.alarms;
      } catch (err) {
        this.err(err);
      }
    },
    formatDate(date) {
      return moment.utc(date).format('DD-MM-YYYY HH:mm');
    },

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    activatedAtTooltip(item) {
      return `activated ${this.formatDate(new Date(item.activatedAt))}`;
    },
  },
};
</script>
<style>
.wrapped-text {
  word-wrap: break-word;
  min-width: 160px;
  max-width: 160px;
}
</style>


import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  inject: ['$larva'],
})
export default class extends Vue {
  public placeholder = {
    formSubTitle: '',
  };
  public edit = false;
  public system = {
    name: '',
    data: '{}',
    type: 'MQTT',
  };
  public types = [
    { value: 'MQTT', text: 'MQTT' },
    { value: 'OBIX', text: 'Obix' },
    { value: 'BENCH', text: 'Bench' },
  ];

  private errors = [];

  public created() {
    const { id } = this.$route.params;
    if (id) {
      this.getSystem(id);
      this.edit = true;
      this.placeholder.formSubTitle = 'Edit Technical System';
    } else {
      this.edit = false;
      this.placeholder.formSubTitle = 'New Technical System';
    }
  }
  private getSystem(id: string) {
    this.$larva
      .request('iot-2/cmd/getTechnicalConnection/fmt/json', { id })
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, updatedAt, createdAt, ...system } = res.connection;
        this.system = { ...system, data: JSON.stringify(data) };
      })
      .catch(this.err);
  }

  public save() {
    try {
      const { id } = this.$route.params;
      const topic = id ? 'iot-2/cmd/editTechnicalConnection/fmt/json' : 'iot-2/cmd/createTechnicalConnection/fmt/json';
      const { data, ...rest } = this.system;
      const req = {
        ...(id ? { id } : {}),
        ...rest,
        data: JSON.parse(data),
      };
      this.$larva
        .request(topic, req)
        .then(() => {
          this.success('Done', 'Saved');
          this.$router.replace({ name: 'app.technicalconnections' });
        })
        .catch(this.err);
    } catch (err) {
      this.err(err);
    }
  }

  public state(e) {
    return this.errors[e] ? false : null;
  }

  private success(title, msg) {
    this.$notify({
      title,
      text: msg,
      type: 'success',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private err(err: any) {
    let errormsg: string | null = null;
    if (err && err.message === 'ValidationError') {
      this.errors = err.details || {};
      errormsg = 'Validation Error';
    }
    this.$notify({
      title: 'Error',
      text: errormsg || err.message || 'Unknown error',
      type: 'error',
    });
  }
}

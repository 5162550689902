
import Component from 'vue-class-component';
import Vue from 'vue';

// eslint-disable-next-line no-use-before-define
@Component<Bench>({
  inject: ['$larva'],
  // eslint-disable-next-line indent
})
export default class Bench extends Vue {
  private stats = null;
  private errors: Record<string, string> = {};
  private interval: number | undefined = undefined;

  mounted() {
    this.getBenchStats();
    this.interval = setInterval(() => {
      this.getBenchStats(); // @TODO: when browser login token expires, then this keeps executing each second even though we have navigated to /login
    }, 1000);
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  getBenchStats() {
    return this.$larva
      .request('iot-2/cmd/getBenchStats/fmt/json', null)
      .then((data) => {
        this.stats = data;
      })
      .catch((err) => console.error(err));
  }
  getVariant(value, max) {
    if (value <= max / 3) {
      return 'success';
    }
    if (value <= max / 2) {
      return 'warning';
    }
    return 'danger';
  }
  err(err) {
    // larva promise error handler
    let errormsg;
    if (err.message === 'ValidationError') {
      this.errors = err.details || {};
      errormsg = 'Validation Error';
    } else {
      errormsg = err.message;
    }
    this.$notify({
      title: 'Error',
      text: errormsg || 'Unknown error',
      type: 'error',
    });
  }
}

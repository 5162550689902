<template>
  <b-card id="device-diagnostics-card" title="Diagnostics" sub-title="Larva Hardware Diagnostic indicators" class="mb-4">
    <b-container v-if="diagnostics">
      <table id="diagnostics-table" class="table">
        <tr>
          <td>Power</td>
          <td>
            <b-container v-for="(value, key) in diagnostics.power" :key="key" class="bv-example-row">
              <b-row v-if="key !== 'io'" style="padding-top: 2px">
                <b-col>
                  {{ key }}
                </b-col>
                <b-col>
                  {{ value }}
                </b-col>
              </b-row>
            </b-container>
          </td>
        </tr>
        <tr>
          <td>Charger</td>
          <td>
            <b-container v-for="(value, key) in diagnostics.charger" :key="key" class="bv-example-row">
              <b-row v-if="key !== 'io'" style="padding-top: 2px">
                <b-col>
                  {{ key }}
                </b-col>
                <b-col>
                  {{ value }}
                </b-col>
              </b-row>
            </b-container>
          </td>
        </tr>
      </table>
    </b-container>
  </b-card>
</template>

<script>
export default {
  inject: ['$larva'],
  data: () => {
    return {
      diagnostics: null,
    };
  },
  created() {
    this.getDeviceDiagnostics();
  },
  methods: {
    err(err) {
      this.$notify({
        title: 'Error',
        text: err.message || 'Unknown error',
        type: 'error',
      });
    },
    getDeviceDiagnostics() {
      return this.$larva
        .request('iot-2/cmd/getDeviceDiagnostics/fmt/json', null)
        .then((data) => {
          this.diagnostics = data;
        })
        .catch(this.err);
    },
  },
};
</script>

<style></style>


import Vue from 'vue';
import Component from 'vue-class-component';
import * as _ from 'lodash';
import { LarvaEvent, LarvaMessage } from '@larva.io/clouddevice';
import * as Cookie from 'tiny-cookie';

// eslint-disable-next-line no-use-before-define
@Component<Dashboard>({
  watch: {
    unit() {
      this.getUIConfiguration();
    },
  },
  inject: ['$larva'],
})
export default class Dashboard extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private uiconfig: Record<string, any> = [];
  private showUnits = false;
  private coreInitialized = false;
  private units = [{ value: null, text: '-- All --' }];
  private unit = null;
  private connection = false;
  private videoServer = '';

  created() {
    const token = Cookie.get('token');
    const unit = Cookie.get('unit');
    this.videoServer = `${window.location.origin.replace(/^https/, 'wss').replace(/^http/, 'ws')}/video-proxy?token=${token}${unit ? `&unit=${unit}` : ''}&`;
    this.$larva.addEventListener('open', this.connectionOpen);
    this.$larva.addEventListener('closed', this.connectionClose);
    this.$larva.addEventListener('error', this.connectionError);
    // this.$larva.addEventListener('data', this.handleData as EventListener);
    // eslint-disable-next-line no-undef
    this.$larva.addEventListener('broadcast', this.handleData as EventListener);
    if (this.$larva.readyState() === 1) {
      this.getUIConfiguration();
      if (!this.$larva.getUnit()) {
        this.getUnits();
      }
    }
  }
  beforeDestroy() {
    this.$larva.removeEventListener('open', this.connectionOpen);
    this.$larva.removeEventListener('closed', this.connectionClose);
    this.$larva.removeEventListener('error', this.connectionError);
    // this.$larva.removeEventListener('data', this.handleData as EventListener);
    // eslint-disable-next-line no-undef
    this.$larva.removeEventListener('broadcast', this.handleData as EventListener);
  }
  connectionOpen() {
    this.getUIConfiguration();
    if (!this.$larva.getUnit()) {
      this.getUnits();
    }
  }
  connectionClose() {
    this.connection = false;
  }
  connectionError(ev: Event) {
    const err = (ev as CustomEvent<Error>).detail;
    this.err(err);
  }
  handleData(ev: LarvaEvent<LarvaMessage>) {
    const topic = ev.detail.topic;
    const data = ev.detail.payload.data;
    // flow updated, request new inteface nodes
    if (topic === 'iot-2/evt/larvaCoreConfig/fmt/json') {
      this.getUIConfiguration();
      return;
    }
    // check weather topic matches component ID from all components that we have
    // if we do have it, set the event data for component.
    let match = /^iot-2\/evt\/([^\/]+)\/fmt\/json$/g.exec(topic); // eslint-disable-line
    if (match) {
      const nodeId = match[1];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if (!this.$refs[nodeId] || !Array.isArray(this.$refs[nodeId]) || (this.$refs[nodeId] as unknown[]).length === 0) {
        console.warn(`Could not find node(s) with id "${nodeId}"`);
        // nodes with this ref not found
        return;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs[nodeId] as any[]).forEach((node) => {
        // eslint-disable-next-line no-unused-expressions
        node.input && node.input(data);
      });
    }
  }
  getUnits() {
    this.$larva
      .request('iot-2/cmd/getUnits/fmt/json')
      .then((data) => {
        this.showUnits = true;
        this.units = _.map(data.units, (unit) => ({ value: unit.id, text: unit.name }));
        this.units.unshift({ value: null, text: '-- All --' });
      })
      .catch(this.err);
  }
  err(err) {
    this.$notify({
      title: 'Error',
      text: err.message || 'Unknown error',
      type: 'error',
    });
  }
  async handleNodeOutput(data) {
    data.preventDefault(); // important since larva cloud deice does not do that yet
    data.stopPropagation(); // important since larva cloud deice does not do that yet
    try {
      await this.$larva.handleNodeOutput(data);
    } catch (err) {
      this.err(err);
    }
  }
  async handleNodeRequest(data) {
    try {
      await this.$larva.handleNodeRequest(data);
    } catch (err) {
      this.err(err);
    }
  }
  getUIConfiguration() {
    this.uiconfig = [];
    const where = this.unit ? { where: { unitId: this.unit } } : {};
    this.$larva
      .request('iot-2/cmd/larvaUINodes/fmt/json', {
        ...where,
      })
      .then((data) => {
        const uiconfig = data.nodes || [];
        // map linkednodes, since linkednodes
        this.uiconfig = uiconfig
          .map((node) => {
            let linkednodes = [];
            if (Array.isArray(node.ui?.linkednodes) && node.ui.linkednodes.length > 0) {
              linkednodes = node.ui.linkednodes
                .map((nodeId: string) => uiconfig.find((n) => n.node?.id === nodeId))
                .filter((n) => !!n)
                .sort((a, b) => parseInt(b.ui?.rating || 1, 10) - parseInt(a.ui?.rating || 1, 10));
            }
            return { ...node, ui: { ...node.ui, linkednodes } };
          })
          .sort((a, b) => parseInt(b.ui?.rating || 1, 10) - parseInt(a.ui?.rating || 1, 10));
        this.coreInitialized = true;
      })
      .catch(this.err);
  }
}

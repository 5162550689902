<template>
  <div class="sidebar">
    <div id="style-3" class="sidebar-wrapper">
      <div class="logo">
        <div class="logo-img">
          <img src="../../assets/svg/logo.svg" alt="Larva.io" />
        </div>
      </div>
      <slot />
      <ul ref="nav" class="nav">
        <slot name="links" />
      </ul>
      <moving-arrow :move-y="arrowMovePx" />
      <footer>
        <div class="copyright">
          <slot name="footer" />
        </div>
      </footer>
    </div>
  </div>
</template>
<script>
import MovingArrow from './MovingArrow.vue';

export default {
  components: {
    MovingArrow,
  },
  data() {
    return {
      linkHeight: 66,
      activeLinkIndex: 0,
      windowWidth: 0,
      isWindows: false,
      hasAutoHeight: false,
    };
  },
  computed: {
    /**
     * Styles to animate the arrow near the current active sidebar link
     * @returns {{transform: string}}
     */
    arrowMovePx() {
      return this.linkHeight * this.activeLinkIndex;
    },
  },
  mounted() {
    this.$watch('$route', this.findActiveLink, {
      immediate: true,
    });
  },
  methods: {
    findActiveLink() {
      const list = this.$refs.nav.childNodes;
      for (let i = 0; i < list.length; i += 1) {
        const link = list[i];
        if (link.classList.contains('active')) {
          this.activeLinkIndex = i;
          break;
        }
      }
    },
  },
};
</script>
<style></style>


/* eslint-disable no-undef */
import Component from 'vue-class-component';
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSlidersH, faWrench, faBell, faClipboardList, faUsers, faCogs, faNetworkWired, faLock, faHeart, faCode, faTable, faShieldAlt, faChartBar } from '@fortawesome/free-solid-svg-icons';
import { LarvaError, LarvaEvent, LarvaEventListener, LarvaMessage } from '@larva.io/clouddevice';
import * as Cookie from 'tiny-cookie';
import DashboardContent from './Content.vue';
import SwUpdateComponent from '../../components/SwUpdate.vue';

library.add(faSlidersH, faWrench, faBell, faClipboardList, faUsers, faCogs, faNetworkWired, faLock, faHeart, faCode, faTable, faShieldAlt, faChartBar);

@Component({
  inject: ['$larva'],
  components: {
    DashboardContent,
    SwUpdateComponent,
  },
})
export default class extends Vue {
  connection = false;
  unitUser = false;
  // tokenAboutToExpire = false;
  // countdown = 30;

  created() {
    this.unitUser = !!Cookie.get('unit');
    if (this.$larva.readyState() === 1) {
      this.connection = true;
    }
    this.$larva.addEventListener('open', this.connectionOpen);
    this.$larva.addEventListener('closed', this.connectionClosed);
    this.$larva.addEventListener('broadcast', this.handleBroadcast as LarvaEventListener);
    this.$larva.addEventListener('error', this.handleError as LarvaEventListener);
  }
  beforeDestroy() {
    this.$larva.removeEventListener('open', this.connectionOpen);
    this.$larva.removeEventListener('closed', this.connectionClosed);
    this.$larva.removeEventListener('broadcast', this.handleBroadcast as LarvaEventListener);
    this.$larva.removeEventListener('error', this.handleError as LarvaEventListener);
  }
  /* tokenAboutToExpire (e) {
      // TODO: should we use refresh tokens or not ?
      this.countdown = parseInt(Math.floor(e.detail));
      this.tokenAboutToExpire = true;
      alert('Your access token will epxire in ' + this.countdown + 's');
      this.interval = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(this.interval);
          this.$router.replace({ name: 'logout' });
        }
      }, 1000);
    }, */
  handleError(ev: LarvaEvent<LarvaError>) {
    if (ev.detail.statusCode === 401) {
      this.error('Error', 'Session timed out');
      this.$router.replace({ name: 'logout' });
    } else if (ev.detail.message) {
      this.error('Error', ev.detail.message);
    }
  }
  handleBroadcast(ev: LarvaEvent<LarvaMessage>) {
    if (ev.detail.topic === 'iot-2/evt/errors/fmt/json') {
      this.error('Error', ev.detail.payload.err?.message || 'Controller connection error');
    }
  }
  connectionOpen() {
    this.connection = true;
  }
  connectionClosed() {
    this.connection = false;
    this.error('Communication error', 'Connection to Larva controller closed');
  }
  error(title: string, msg: string) {
    this.$notify({
      title,
      text: msg,
      type: 'error',
    });
  }
  logout() {
    this.$router.replace({ name: 'logout' });
  }
}

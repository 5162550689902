<template>
  <div>
    <div v-if="error">
      <b-badge variant="warning">
        {{ error }}
      </b-badge>
      <br />
    </div>
    <div v-if="items" class="pl-2 pr-2">
      <b-button-toolbar v-if="!error" key-nav class="float-right text-right" aria-label="Button Toolbar">
        <b-button-group class="text-right mb-2">
          <b-btn id="new_if_btn" variant="success" title="New Interface" :disabled="!items" @click="addInterface()"> <fa-icon icon="plus" /> New Interface </b-btn>
        </b-button-group>
      </b-button-toolbar>
      <template v-if="!error">
        <b-table hover :fields="fields" :items="items">
          <template #cell(match)="{ item }">
            {{ configLabel(item) }}
          </template>
          <template #cell(config)="{ item }">
            <b-row v-for="(value, key) in item.config.Network" :key="key"> {{ key }}: {{ value.join(',') }} </b-row>
          </template>
          <template #cell(actions)="{ item, index }">
            <a href="#" title="Edit" class="mr-2" @click.prevent="editConfig(item, index)">
              <fa-icon icon="edit" />
            </a>
            <a v-if="!item.system" href="#" title="Remove" class="text-danger" @click.prevent="deleteConfig(item, index)">
              <fa-icon icon="trash" />
            </a>
            <a v-if="item.system" href="#" title="Remove" class="text-white" style="cursor: inherit">
              <fa-icon icon="trash" />
            </a>
          </template>
        </b-table>
        <div class="text-center mt-2">
          <b-btn id="save_btn" variant="success" title="Save network config" :disabled="!items || !dirty" @click="setNetworkConfig()"> Save </b-btn>
        </div>
        <EditNetworkConfig ref="editmodal" @saveDone="saveDone" />
      </template>
    </div>
  </div>
</template>
<script>
import * as _ from 'lodash';
import EditNetworkConfig from './EditNetworkConfig.vue';

export default {
  inject: ['$larva'],
  name: 'NetworkConfigList',
  components: {
    EditNetworkConfig,
  },
  data: () => ({
    fields: [
      {
        key: 'match',
        sortable: false,
        slot: true,
        label: 'Match',
      },
      {
        label: 'Network',
        key: 'config',
        slot: true,
      },
      {
        label: '',
        key: 'actions',
        slot: true,
        tdClass: 'text-right',
      },
    ],
    items: null,
    error: null,
    editItem: null,
    editItemIndex: null,
    dirty: null,
  }),
  mounted() {
    this.getNetworkConfig();
  },
  methods: {
    configLabel(item) {
      if (item.config.Match) {
        if (item.config.Match.Name) {
          return item.config.Match.Name.join(', ');
        }
        if (item.config.Match.MACAddress) {
          return item.config.Match.MACAddress.join(', ');
        }
      }
      return undefined;
    },
    async restartNetwork() {
      this.$notify({
        title: '',
        text: 'Restarting network',
        type: 'info',
      });
      try {
        await this.$larva.request('iot-2/cmd/restartNetwork/fmt/json', null);
      } catch (err) {
        this.err(err);
      }
    },
    async getNetworkConfig() {
      try {
        this.error = null;
        const data = await this.$larva.request('iot-2/cmd/getNetworkConfig/fmt/json', null);
        this.items = data?.configs || [];
      } catch (err) {
        console.error(err);
        this.error = 'Daemon connection failed';
      }
    },
    async setNetworkConfig() {
      try {
        await this.$larva.request('iot-2/cmd/setNetworkConfig/fmt/json', { configs: this.items });
        this.success('Network config saved');
        await this.restartNetwork();
        await this.getNetworkConfig();
        this.dirty = false;
      } catch (err) {
        this.err(err);
        throw err;
      }
    },
    initConfig() {
      return {
        config: {
          Match: {},
          Network: {},
        },
      };
    },
    editConfig(item, index) {
      const editItem = _.cloneDeep(item);
      editItem.index = index;
      this.$refs.editmodal.edit(editItem);
    },
    addInterface() {
      const newItem = this.initConfig();
      this.$refs.editmodal.new(newItem);
    },
    deleteConfig(item, index) {
      this.dirty = true;
      this.items.splice(index, 1);
    },
    saveDone(item) {
      this.dirty = true;
      if (item.index === undefined) {
        // a new item
        this.items.push(item);
      } else {
        this.items[item.index] = item;
      }
      this.items = this.items.map((i) => i);
      this.$refs.editmodal.hide();
    },
    err(err) {
      // larva promise error handler
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      } else {
        errormsg = err.message;
      }
      this.$notify({
        title: 'Error',
        text: errormsg || 'Unknown error',
        type: 'error',
      });
    },
    success(message) {
      this.$notify({
        title: 'Done',
        text: message,
        type: 'success',
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>

<template>
  <b-card title="Configuration backup" sub-title="Create, upload, download and restore configuration backups" class="mb-4">
    <div v-if="error">
      <b-badge variant="warning">
        {{ error }}
      </b-badge>
      <br />
    </div>
    <template v-if="!error">
      <input id="file" ref="file" type="file" style="display: none" @change="handleFileUpload()" />
      <b-button-toolbar key-nav class="float-right" aria-label="Button Toolbar">
        <b-button-group class="usersbuttons">
          <b-button variant="success" :disabled="!!error" @click="createBackup"> Create backup </b-button>
        </b-button-group>
        <b-button-group class="usersbuttons">
          <b-button variant="primary" :disabled="!!error" @click="$refs.file.click()"> Upload backup </b-button>
        </b-button-group>
      </b-button-toolbar>
      <div class="pl-4 pr-4 text-right">
        <b-table :items="backupList" :fields="fields">
          <template v-slot:cell(actions)="{ item }">
            <b-button @click="applyBackup(item)"> Restore </b-button>
            <a :href="'/backup/' + item.filename" title="Download" class="ml-2">
              <fa-icon icon="download" />
            </a>
            <a href="#" title="Remove" class="text-danger ml-2" @click.prevent="removeBackup(item)">
              <fa-icon icon="trash" />
            </a>
          </template>
        </b-table>
      </div>
    </template>
  </b-card>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

library.add(faDownload);
library.add(faTrash);

export default {
  inject: ['$larva'],
  data() {
    return {
      backupList: [],
      fields: [
        {
          label: 'Name',
          key: 'filename',
          thStyle: {
            display: 'none',
          },
          tdClass: 'align-middle',
        },
        {
          key: 'actions',
          label: '',
          thStyle: {
            display: 'none',
          },
        },
      ],
      error: null,
    };
  },
  created() {
    this.getBackupList();
  },
  methods: {
    async getBackupList() {
      try {
        const rsp = await this.$larva.request('iot-2/cmd/getConfBackupList/fmt/json');
        if (Array.isArray(rsp.backups)) {
          this.backupList = rsp.backups;
        } else {
          this.backupList = [];
        }
      } catch (err) {
        console.error(err);
        this.error = 'Daemon connection failed';
        /* this.$notify({
        title: 'Failed to get',
        text: data.error,
        type: 'error',
      }); */
      }
    },
    async createBackup() {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Backup creation requires a device shut down. Are you sure you want to continue?') === true) {
        try {
          await this.$larva.request('iot-2/cmd/createConfBackup/fmt/json');
          this.$notify({
            title: 'In progress',
            text: 'Creating configuration backup...',
            type: 'warning',
          });
        } catch (err) {
          this.$notify({
            title: 'Error',
            text: err.message,
            type: 'error',
          });
          console.error(err);
        }
      }
    },
    async removeBackup(backup) {
      try {
        // eslint-disable-next-line no-restricted-globals, no-alert
        if (confirm('Are you sure?') === true) {
          await this.$larva.request('iot-2/cmd/removeConfBackup/fmt/json', { filename: backup.filename });
          await this.getBackupList();
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          text: err.message,
          type: 'error',
        });
      }
    },
    async applyBackup(backup) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Backup restoration requires a device shut down. Are you sure you want to continue?') === true) {
        try {
          await this.$larva.request('iot-2/cmd/applyConfBackup/fmt/json', { filename: backup.filename });
          this.$notify({
            title: 'In progress',
            text: 'Restoring from configuration backup...',
            type: 'warning',
          });
        } catch (err) {
          this.$notify({
            title: 'Error',
            text: err.message,
            type: 'error',
          });
          console.error(err);
        }
      }
    },
    async handleFileUpload() {
      // eslint-disable-next-line
      const data = Buffer.from(
        await new Promise((resolve) => {
          const fr = new FileReader();
          fr.onload = () => {
            resolve(fr.result);
          };
          fr.readAsBinaryString(this.$refs.file.files[0]);
        }),
        'binary'
      );
      try {
        await this.$larva.request('iot-2/cmd/uploadConfBackup/fmt/json', {
          filename: this.$refs.file.files[0].name,
          data: data.toString('base64'),
        });
        this.$notify({
          title: 'Done',
          text: 'Backup file uploaded',
          type: 'success',
        });
        await this.getBackupList();
      } catch (err) {
        this.$notify({
          title: 'Error',
          text: err.message,
          type: 'error',
        });
        console.error(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>

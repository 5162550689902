<template>
  <div>
    <b-row class="justify-content-center">
      <b-col sm="12" md="6">
        <b-card title="License Plate" :sub-title="placeholder.formSubTitle">
          <form @submit.prevent="save">
            <b-form-group label="Plate">
              <b-form-input v-model="plate.plate" :state="state('plate')" type="text" placeholder="Plate" />
            </b-form-group>
            <b-form-group label="Firstname">
              <b-form-input v-model="plate.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
            </b-form-group>
            <b-form-group label="Lastname">
              <b-form-input v-model="plate.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
            </b-form-group>
            <b-form-group label="E-mail">
              <b-form-input v-model="plate.email" :state="state('email')" type="text" placeholder="E-mail" />
            </b-form-group>
            <b-form-group label="Phone">
              <b-form-input v-model="plate.phone" :state="state('phone')" type="text" placeholder="Phone" />
            </b-form-group>
            <div class="text-center">
              <router-link :to="{ name: 'app.units.view', params: { id: this.$route.params.unitId } }">
                <b-button variant="secondary"> Cancel </b-button>
              </router-link>
              <b-button type="submit" variant="success"> Save </b-button>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  inject: ['$larva'],
  data() {
    return {
      plate: {
        plate: '',
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
      },
      placeholder: {
        formSubTitle: '',
      },
      editing: false,
      errors: {},
    };
  },
  created() {
    const { id } = this.$route.params;
    if (id) {
      this.editing = true;
      this.getPlate();
      this.placeholder.formSubTitle = 'Edit License Plate';
    } else {
      this.editing = false;
      this.placeholder.formSubTitle = 'New License Plate';
    }
  },
  methods: {
    err(err) {
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      }
      this.$notify({
        title: 'Error',
        text: errormsg || err.message,
        type: 'error',
      });
    },
    state(e) {
      return this.errors[e] ? false : null;
    },
    success(title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    getPlate() {
      const { id, unitId } = this.$route.params;
      this.$larva
        .request('iot-2/cmd/getUnitLicensePlate/fmt/json', { id, unitId })
        .then((data) => {
          this.plate = data.plate;
        })
        .catch(this.err);
    },
    save() {
      const { id, unitId } = this.$route.params;
      this.plate.unitId = unitId;
      // remove empty strings from the object
      Object.keys(this.plate).forEach((key) => {
        if (!this.plate[key]) {
          delete this.plate[key];
        }
      });
      const topic = id ? 'iot-2/cmd/editUnitLicensePlate/fmt/json' : 'iot-2/cmd/createUnitLicensePlate/fmt/json';
      const plate = id ? { id, ...this.plate } : this.plate;
      this.$larva
        .request(topic, plate)
        .then(() => {
          this.success('Done', 'License Plate saved');
          this.$router.replace({ name: 'app.units.view', params: { id: unitId } });
        })
        .catch(this.err);
    },
  },
};
</script>

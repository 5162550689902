<template>
  <div>
    <b-row class="justify-content-center">
      <b-col sm="12" md="6">
        <b-card title="User" :sub-title="placeholder.formSubTitle">
          <form @submit.prevent="save">
            <b-form-group label="Username">
              <b-form-input v-model="user.username" :disabled="editingUser" :state="state('username')" type="text" placeholder="Username" />
            </b-form-group>
            <b-form-group label="Password">
              <b-form-input v-model="user.password" :state="state('password')" type="password" placeholder="password" />
            </b-form-group>
            <b-form-group label="Firstname">
              <b-form-input v-model="user.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
            </b-form-group>
            <b-form-group label="Lastname">
              <b-form-input v-model="user.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
            </b-form-group>
            <b-form-group label="Role">
              <b-form-select v-model="user.role" :disabled="editingUser" :state="state('role')" :options="roles" />
            </b-form-group>
            <div class="text-center">
              <router-link :to="{ name: 'app.users' }">
                <b-button variant="secondary"> Cancel </b-button>
              </router-link>
              <b-button type="submit" variant="success"> Save </b-button>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  inject: ['$larva'],
  data() {
    return {
      selectedRole: null,
      roles: [
        { value: 'INSTALLER', text: 'Installer' },
        { value: 'SYSMASTER', text: 'System Master' },
      ],
      user: {
        username: '',
        firstname: '',
        lastname: '',
        password: '',
        role: 'SYSMASTER',
      },
      placeholder: {
        formSubTitle: '',
        password: '',
      },
      editingUser: false,
      errors: {},
    };
  },
  created() {
    const { id } = this.$route.params;
    if (id) {
      this.editingUser = true;
      this.getUser();
      this.placeholder.formSubTitle = 'Edit user';
      this.placeholder.password = "Leave blank, if you don't want to change";
    } else {
      this.editingUser = false;
      this.placeholder.formSubTitle = 'New User';
      this.placeholder.password = 'Password';
    }
  },
  methods: {
    err(err) {
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      }
      this.$notify({
        title: 'Error',
        text: errormsg || err.message,
        type: 'error',
      });
    },
    state(e) {
      return this.errors[e] ? false : null;
    },
    success(title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    getUser() {
      const { id } = this.$route.params;
      this.$larva
        .request('iot-2/cmd/getUser/fmt/json', { id })
        .then((data) => {
          this.user = data.user;
        })
        .catch(this.err);
    },
    save() {
      const { id } = this.$route.params;
      const topic = id ? 'iot-2/cmd/editUser/fmt/json' : 'iot-2/cmd/createUser/fmt/json';
      const user = {
        id,
        firstname: this.user.firstname,
        lastname: this.user.lastname,
        ...(this.user.password ? { password: this.user.password } : undefined),
        // supply cloud, role and username only when creating
        ...(!id ? { cloudId: this.user.cloudId } : undefined),
        ...(!id ? { role: this.user.role } : undefined),
        ...(!id ? { username: this.user.username } : undefined),
      };
      this.$larva
        .request(topic, user)
        .then(() => {
          this.success('Done', 'User saved');
          this.$router.replace({ name: 'app.users' });
        })
        .catch(this.err);
    },
  },
};
</script>

<template>
  <div>
    <b-row class="justify-content-center">
      <b-col sm="12" md="6">
        <b-card title="Card" :sub-title="placeholder.formSubTitle">
          <form @submit.prevent="save">
            <b-form-group v-if="!editing" label="Card ID">
              <b-form-input v-model="card.card" :state="state('card')" type="text" placeholder="Card id" />
              <div v-if="!editing && cardreaders.length > 0" class="input-group mt-3">
                <b-form-select id="cardreaders" v-model="selectedCardReader" :options="cardreaders" />
                <b-btn class="btn-sm" :disabled="readCardRequesting" @click="readCard(selectedCardReader)">
                  <fa-icon v-if="readCardRequesting" icon="spinner" spin />
                  <fa-icon v-if="!readCardRequesting" icon="id-card-alt" />
                  From reader
                </b-btn>
              </div>
            </b-form-group>
            <b-form-group label="Pin code">
              <b-form-input v-model="card.pin" :state="state('pin')" type="password" placeholder="Pin (optional)" />
            </b-form-group>
            <b-form-group label="Firstname">
              <b-form-input v-model="card.firstname" :state="state('firstname')" type="text" placeholder="Firstname" />
            </b-form-group>
            <b-form-group label="Lastname">
              <b-form-input v-model="card.lastname" :state="state('lastname')" type="text" placeholder="Lastname" />
            </b-form-group>
            <b-form-group label="E-mail">
              <b-form-input v-model="card.email" :state="state('email')" type="text" placeholder="E-mail" />
            </b-form-group>
            <b-form-group label="Phone">
              <b-form-input v-model="card.phone" :state="state('phone')" type="text" placeholder="Phone" />
            </b-form-group>
            <div class="text-center">
              <router-link :to="{ name: 'app.units.view', params: { id: this.$route.params.unitId } }">
                <b-button variant="secondary"> Cancel </b-button>
              </router-link>
              <b-button type="submit" variant="success"> Save </b-button>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faIdCardAlt } from '@fortawesome/free-solid-svg-icons';

library.add(faSpinner, faIdCardAlt);

// TODO: two password fields
export default {
  inject: ['$larva'],
  data() {
    return {
      cardreaders: [],
      selectedCardReader: null,
      readCardRequesting: false,
      selectedRole: null,
      card: {
        card: '',
        firstname: '',
        lastname: '',
        pin: '',
      },
      placeholder: {
        formSubTitle: '',
      },
      editing: false,
      errors: {},
    };
  },
  created() {
    const { id } = this.$route.params;
    if (id) {
      this.editing = true;
      this.getcard();
      this.placeholder.formSubTitle = 'Edit card';
    } else {
      this.getReaders();
      this.editing = false;
      this.placeholder.formSubTitle = 'New card';
    }
  },
  methods: {
    err(err) {
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      }
      this.$notify({
        title: 'Error',
        text: errormsg || err.message,
        type: 'error',
      });
    },
    state(e) {
      return this.errors[e] ? false : null;
    },
    success(title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    getcard() {
      const { id, unitId } = this.$route.params;
      this.$larva
        .request('iot-2/cmd/getUnitCard/fmt/json', { id, unitId })
        .then((data) => {
          this.card = data.card;
        })
        .catch(this.err);
    },
    getReaders() {
      this.$larva
        .request('iot-2/cmd/getCardReaders/fmt/json')
        .then((data) => {
          this.cardreaders = data.readers.map((reader) => {
            // eslint-disable-next-line no-param-reassign
            reader.value = reader.id;
            // eslint-disable-next-line no-param-reassign
            reader.text = reader.name;
            return reader;
          });
          this.selectedCardReader = this.cardreaders.length ? this.cardreaders[0].value : '';
        })
        .catch(this.err);
    },
    readCard(id) {
      const { dom } = this.$store.getters;
      if (id) {
        this.readCardRequesting = true;
        this.$larva
          .request('iot-2/cmd/cardReaderReadCard/fmt/json', { id }, dom)
          .then((data) => {
            this.readCardRequesting = false;
            this.card.card = data.card;
          })
          .catch((err) => {
            this.readCardRequesting = false;
            throw err;
          })
          .catch(this.err);
      }
    },
    save() {
      const { id, unitId } = this.$route.params;
      this.card.unitId = unitId;
      const topic = id ? 'iot-2/cmd/editUnitCard/fmt/json' : 'iot-2/cmd/createUnitCard/fmt/json';
      const card = id ? { id, ...this.card } : this.card;
      if (!card.pin) {
        delete card.pin;
      }
      this.$larva
        .request(topic, card)
        .then(() => {
          this.success('Done', 'Card saved');
          this.$router.replace({ name: 'app.units.view', params: { id: unitId } });
        })
        .catch(this.err);
    },
  },
};
</script>

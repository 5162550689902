<template>
  <div>
    <b-row v-if="this.$route.params.id">
      <b-col>
        <b-card title="Unit Users" sub-title="Edit users">
          <b-button-toolbar key-nav class="float-right" aria-label="Button Toolbar">
            <b-button-group class="usersbuttons">
              <router-link :to="{ name: 'app.units.users.new', params: { unitId: this.$route.params.id } }">
                <b-btn variant="success" title="New User">
                  <fa-icon icon="plus" />
                  New User
                </b-btn>
              </router-link>
            </b-button-group>
          </b-button-toolbar>

          <b-table hover :fields="usersFields" :items="deviceUsers">
            <template #cell(updatedAt)="{ item }">
              <span>{{ item.updatedAt | moment('lll') }}</span>
            </template>
            <template #cell(createdAt)="{ item }">
              <span>{{ item.createdAt | moment('lll') }}</span>
            </template>
            <template #cell(cloudId)="{ item }">
              <b-badge v-if="item.cloudId" variant="warning"> {{ item.cloudId }} </b-badge>
            </template>
            <template #cell(unitrole)="{ item }">
              <b-badge v-if="item.unitrole == 'MASTER'" variant="info"> Master </b-badge>
              <b-badge v-if="item.unitrole == 'USER'" variant="secondary"> User </b-badge>
            </template>
            <template #cell(actions)="{ item }">
              <router-link title="Edit" :to="{ name: 'app.units.users.edit', params: { unitId: item.unitId, id: item.id } }">
                <fa-icon icon="edit" />
              </router-link>
              <a href="#" title="Remove" class="text-danger ml-2" @click.prevent="removeUser(item)">
                <fa-icon icon="trash" />
              </a>
            </template>
          </b-table>
          <b-pagination :total-rows="deviceUsersCount" :per-page="limit" small @change="getUsers" />
        </b-card>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col>
        <b-card title="Unit Cards" sub-title="Edit Cards">
          <b-button-toolbar key-nav class="float-right" aria-label="Button Toolbar">
            <b-button-group class="usersbuttons">
              <router-link :to="{ name: 'app.units.cards.new', params: { unitId: this.$route.params.id } }">
                <b-btn variant="success" title="New Card">
                  <fa-icon icon="plus" />
                  New Card
                </b-btn>
              </router-link>
            </b-button-group>
          </b-button-toolbar>

          <b-table hover :fields="cardsFields" :items="deviceCards">
            <template #cell(updatedAt)="{ item }">
              <span>{{ item.updatedAt | moment('lll') }}</span>
            </template>
            <template #cell(createdAt)="{ item }">
              <span>{{ item.createdAt | moment('lll') }}</span>
            </template>
            <template #cell(actions)="{ item }">
              <router-link title="Edit" :to="{ name: 'app.units.cards.edit', params: { unitId: item.unitId, id: item.id } }">
                <fa-icon icon="edit" />
              </router-link>
              <a href="#" title="Remove" class="text-danger ml-2" @click.prevent="removeCard(item)">
                <fa-icon icon="trash" />
              </a>
            </template>
          </b-table>
          <b-pagination :total-rows="deviceCardsCount" :per-page="limit" small @change="getCards" />
        </b-card>
      </b-col>
    </b-row>
    <br />
    <b-row>
      <b-col>
        <b-card title="Unit License Plates" sub-title="Edit License Plates">
          <b-button-toolbar key-nav class="float-right" aria-label="Button Toolbar">
            <b-button-group class="usersbuttons">
              <router-link :to="{ name: 'app.units.plates.new', params: { unitId: this.$route.params.id } }">
                <b-btn variant="success" title="New License Plate">
                  <fa-icon icon="plus" />
                  New License Plate
                </b-btn>
              </router-link>
            </b-button-group>
          </b-button-toolbar>

          <b-table hover :fields="platesFields" :items="devicePlates">
            <template #cell(updatedAt)="{ item }">
              <span>{{ item.updatedAt | moment('lll') }}</span>
            </template>
            <template #cell(createdAt)="{ item }">
              <span>{{ item.createdAt | moment('lll') }}</span>
            </template>
            <template #cell(actions)="{ item }">
              <router-link title="Edit" :to="{ name: 'app.units.plates.edit', params: { unitId: item.unitId, id: item.id } }">
                <fa-icon icon="edit" />
              </router-link>
              <a href="#" title="Remove" class="text-danger ml-2" @click.prevent="removePlate(item)">
                <fa-icon icon="trash" />
              </a>
            </template>
          </b-table>
          <b-pagination :total-rows="devicePlatesCount" :per-page="limit" small @change="getPlates" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  inject: ['$larva'],
  data() {
    return {
      deviceUsers: [],
      deviceUsersCount: 0,
      deviceCards: [],
      deviceCardsCount: 0,
      devicePlates: [],
      devicePlatesCount: 0,
      limit: 50,
      usersFields: [
        /*
        {
          key: 'id',
          sortable: false,
        },
        */
        {
          key: 'username',
          sortable: false,
        },
        {
          key: 'firstname',
          sortable: false,
        },
        {
          key: 'lastname',
          sortable: false,
        },
        {
          key: 'email',
          sortable: false,
        },
        {
          key: 'phone',
          sortable: false,
        },
        {
          key: 'unitrole',
          label: 'Role',
          sortable: false,
        },
        {
          key: 'cloudId',
          sortable: false,
        },
        {
          key: 'validranges',
          sortable: false,
        },
        {
          key: 'updatedAt',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      cardsFields: [
        /*
        {
          key: 'id',
          sortable: false,
        },
        */
        {
          key: 'firstname',
          sortable: false,
        },
        {
          key: 'lastname',
          sortable: false,
        },
        {
          key: 'email',
          sortable: false,
        },
        {
          key: 'phone',
          sortable: false,
        },
        {
          key: 'validranges',
          sortable: false,
        },
        {
          key: 'updatedAt',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      platesFields: [
        /*
        {
          key: 'id',
          sortable: false,
        },
        */
        {
          key: 'plate',
          sortable: false,
        },
        {
          key: 'firstname',
          sortable: false,
        },
        {
          key: 'lastname',
          sortable: false,
        },
        {
          key: 'email',
          sortable: false,
        },
        {
          key: 'phone',
          sortable: false,
        },
        {
          key: 'validranges',
          sortable: false,
        },
        {
          key: 'updatedAt',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.getUsers();
    this.getCards();
    this.getPlates();
  },
  methods: {
    err(err) {
      // larva promise error handler
      this.$notify({
        title: 'Error',
        text: err.message || 'Unknown error',
        type: 'error',
      });
    },
    success(title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    getUsers(page) {
      const { id } = this.$route.params;
      const currentpage = (page || 1) - 1;
      const offset = currentpage * this.limit;
      const { limit } = this;
      this.$larva
        .request('iot-2/cmd/getUnitUsers/fmt/json', { id, offset, limit })
        .then((rsp) => {
          this.deviceUsers = rsp.users;
          this.deviceUsersCount = rsp.count;
        })
        .catch(this.err);
    },
    getCards(page) {
      const { id } = this.$route.params;
      const currentpage = (page || 1) - 1;
      const offset = currentpage * this.limit;
      const { limit } = this;
      this.$larva
        .request('iot-2/cmd/getUnitCards/fmt/json', { id, offset, limit })
        .then((rsp) => {
          this.deviceCards = rsp.cards;
          this.deviceCardsCount = rsp.count;
        })
        .catch(this.err);
    },
    getPlates(page) {
      const { id } = this.$route.params;
      const currentpage = (page || 1) - 1;
      const offset = currentpage * this.limit;
      const { limit } = this;
      this.$larva
        .request('iot-2/cmd/getUnitLicensePlates/fmt/json', { id, offset, limit })
        .then((rsp) => {
          this.devicePlates = rsp.plates;
          this.devicePlatesCount = rsp.count;
        })
        .catch(this.err);
    },
    removeUser(user) {
      const { id, unitId } = user;
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure?') === true) {
        this.$larva
          .request('iot-2/cmd/deleteUnitUser/fmt/json', { id, unitId })
          .then(() => {
            this.success('Done', 'User Removed');
            this.getUsers();
          })
          .catch(this.err);
      }
    },
    removeCard(card) {
      const { id, unitId } = card;
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure?') === true) {
        this.$larva
          .request('iot-2/cmd/deleteUnitCard/fmt/json', { id, unitId })
          .then(() => {
            this.success('Done', 'Card Removed');
            this.getCards();
          })
          .catch(this.err);
      }
    },
    removePlate(plate) {
      const { id, unitId } = plate;
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure?') === true) {
        this.$larva
          .request('iot-2/cmd/deleteUnitLicensePlate/fmt/json', { id, unitId })
          .then(() => {
            this.success('Done', 'Plate Removed');
            this.getCards();
          })
          .catch(this.err);
      }
    },
  },
};
</script>

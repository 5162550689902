<template>
  <span>
    <b-link :href="'units/view/' + unitId">
      {{ unitName }}
    </b-link>
  </span>
</template>

<script>
export default {
  inject: ['$larva'],
  props: {
    unitId: {
      type: String,
    },
  },
  data() {
    return {
      unitName: '',
    };
  },
  methods: {
    getUnits(id) {
      this.$larva
        .request('iot-2/cmd/getUnit/fmt/json', { id })
        .then((rsp) => {
          this.unitName = rsp.unit.name;
        })
        .catch(this.err);
    },
  },
  created() {
    this.getUnits(this.unitId);
  },
};
</script>

<style></style>

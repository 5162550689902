<template>
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-md-6 col-sm-12">
        <b-card title="Device info" sub-title="Device metadata values" class="mb-4">
          <div id="device-info" class="table-responsive pl-4 pr-4">
            <table class="table">
              <tr>
                <td>Serial nr:</td>
                <td>
                  <span id="serial-number">{{ _.get(deviceInfo, 'sn') }}</span>
                </td>
              </tr>
              <tr>
                <td>Model:</td>
                <td>{{ _.get(deviceInfo, 'model') }}</td>
              </tr>
              <tr>
                <td>Class:</td>
                <td>{{ _.get(deviceInfo, 'class') }}</td>
              </tr>
              <tr>
                <td>Flow revision:</td>
                <td>{{ flowRevision }}</td>
              </tr>
              <tr>
                <td>Larva.io services issuer:</td>
                <td>{{ larvaServicesIssuer }}</td>
              </tr>
            </table>
          </div>
          <form @submit.prevent="setDeviceMetadata">
            <div class="form-group">
              <label for="devmetaname">Name</label>
              <b-form-input id="devmetaname" v-model="deviceMetadata.name" type="text" placeholder="name" />
            </div>
            <div class="form-group">
              <label for="devmetalocation">Location</label>
              <b-form-input id="devmetalocation" v-model="deviceMetadata.location" type="text" placeholder="location" />
            </div>
            <div class="form-group">
              <label for="devmetadescription">Description</label>
              <b-form-input id="devmetadescription" v-model="deviceMetadata.description" type="text" placeholder="description" />
            </div>
            <div class="text-center">
              <b-button type="submit" variant="success" :disabled="saving"> Save </b-button>
            </div>
          </form>
        </b-card>
        <b-card v-if="_.get(hwConfModifyable, 'ios')" title="I/O configuration" sub-title="Larva Hardware Input/Output configuration">
          <div id="io-conf">
            <form @submit.prevent="saveHWConf">
              <div v-for="(domain, domainKey, ioindex) in hwConfModifyable.ios" :key="ioindex">
                <div style="text-align: center">
                  <span>
                    <strong>{{ _.upperFirst(domainKey) }}</strong>
                  </span>
                </div>
                <div v-if="domainKey !== 'serial'">
                  <div v-for="(ios, ioType, domainindex) in domain" :key="domainindex">
                    <span class="h5">{{ ioTypeLong(ioType) }}</span>
                    <div class="table-responsive pl-4 pr-4">
                      <table class="table">
                        <tr v-for="(io, index) in ios" :key="index">
                          <td style="vertical-align: middle">
                            {{ io.io }}
                          </td>
                          <td>
                            <b-container v-for="(value, key) in io" :key="key" class="bv-example-row">
                              <b-row v-if="key !== 'io'" style="padding-top: 2px">
                                <b-col v-if="_.has(hwConfModifyable, ['iosInfo', domainKey, ioType, key, 'editable'])" class="text-right">
                                  {{ key }}
                                </b-col>
                                <!-- read-only property-->
                                <b-col v-if="_.get(hwConfModifyable, ['iosInfo', domainKey, ioType, key, 'editable']) === 'false'">
                                  {{ value }}
                                </b-col>
                                <!-- editable property -->
                                <b-col v-if="_.get(hwConfModifyable, ['iosInfo', domainKey, ioType, key, 'editable']) === 'true'">
                                  <!-- float (input) -->
                                  <b-form-input
                                    v-if="_.get(hwConfModifyable, ['iosInfo', domainKey, ioType, key, 'propertyType']) === 'float'"
                                    v-model.number="hwConfModifyable.ios[domainKey][ioType][index][key]"
                                    size="sm"
                                    type="number"
                                    min="0"
                                    max="999999999999"
                                    step="0.001"
                                  />
                                  <!-- enum (select) -->
                                  <b-form-select
                                    v-if="_.get(hwConfModifyable, ['iosInfo', domainKey, ioType, key, 'propertyType']) === 'enum'"
                                    v-model="hwConfModifyable.ios[domainKey][ioType][index][key]"
                                    :options="enumOptions(hwConfModifyable.iosInfo[domainKey][ioType][key].enumValues)"
                                    size="sm"
                                  />
                                  <b-form-checkbox
                                    v-if="_.get(hwConfModifyable, ['iosInfo', domainKey, ioType, key, 'propertyType']) === 'boolean'"
                                    v-model="hwConfModifyable.ios[domainKey][ioType][index][key]"
                                    :value="true"
                                    :unchecked-value="false"
                                    size="sm"
                                  />
                                </b-col>
                              </b-row>
                            </b-container>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
                <div v-if="domainKey === 'serial'">
                  <div class="table-responsive pl-4 pr-4">
                    <table class="table">
                      <tr v-for="(io, domainindex) in domain" :key="domainindex">
                        <td style="vertical-align: middle">
                          {{ io.io }}
                        </td>
                        <td>
                          <b-container v-for="(value, key, index) in io" :key="index" class="bv-example-row">
                            <b-row v-if="key !== 'io'">
                              <b-col class="text-right">
                                {{ key }}
                              </b-col>
                              <b-col>{{ value }}</b-col>
                            </b-row>
                          </b-container>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
              <div class="text-center">
                <b-button type="submit" variant="success" :disabled="!confHasChanged() || saving"> Save </b-button>
              </div>
            </form>
          </div>
        </b-card>
      </div>
      <div class="col-md-6 col-sm-12">
        <b-card title="Larva.io Cloud" sub-title="Larva.io Cloud configuration" class="mb-4">
          <div id="cloud-conf">
            <form @submit.prevent="saveCloud">
              <div class="form-group">
                <b-form-checkbox id="enabled" v-model="cloudconfig.enabled">
                  Cloud enabled
                  <b-badge v-if="!cloudconnection" variant="danger"> Disconnected </b-badge>
                  <b-badge v-if="cloudconnection" variant="success"> Connected </b-badge>
                </b-form-checkbox>
              </div>
              <div v-if="cloudconfig.enabled" class="form-group text-center">
                <label class="form-check-label" for="deviceid">Cloud key</label>
                <b-row>
                  <b-col>
                    <b-form-textarea v-model="cloudkey" variant="dark" readonly />
                  </b-col>
                  <b-col md="auto" class="pl-0">
                    <fa-icon class="clickable" v-b-tooltip.hover title="Copy to clipboard" icon="clipboard" @click="clipboard($event)" />
                  </b-col>
                </b-row>
              </div>
              <div class="text-center">
                <b-button type="submit" variant="success" :disabled="saving"> Save </b-button>
              </div>
            </form>
          </div>
        </b-card>
        <HiveSettingsFetch />
        <b-card title="Security Remote Reporting" sub-title="You can report Security System events over SIA-DC09 protocol using Contact-ID" class="mb-4">
          <div id="security-remote-reporting">
            <form @submit.prevent="saveSIARemoteReportConf">
              <b-badge v-if="!siaRemote.connected" variant="danger"> Disconnected </b-badge>
              <b-badge v-if="siaRemote.connected" variant="success"> Connected </b-badge>

              <div class="form-group">
                <b-form-checkbox v-model="siaRemote.enabled"> Remote enabled </b-form-checkbox>
              </div>

              <div v-for="(receiver, index) in siaRemote.servers" :key="`receiver-${index}`">
                <div class="form-group">
                  <label>Receiver {{ index + 1 }} IP</label>
                  <b-form-input v-model="receiver.host" :state="errors.servers ? false : null" type="text" placeholder />
                </div>
                <div class="form-group">
                  <label>Receiver {{ index + 1 }} port</label>
                  <b-form-input v-model="receiver.port" :state="errors.servers ? false : null" type="text" placeholder />
                </div>
              </div>

              <b-btn v-if="!siaRemote.servers || (siaRemote.servers && siaRemote.servers.length < 2)" variant="success" title="New Receiver" @click="addSIAReceiver()">
                <fa-icon icon="plus" /> New Receiver
              </b-btn>

              <div class="form-group">
                <label for="acct">Account number (HEX string)</label>
                <b-form-input id="acct" v-model="siaRemote.acct" :state="errors.acct ? false : null" type="text" placeholder />
              </div>

              <div class="form-group">
                <label for="acct">Encryption password</label>
                <b-form-input id="password" v-model="siaRemote.password" :state="errors.password ? false : null" type="password" placeholder="(Leave empty if no encryption used)" />
              </div>

              <div class="form-group">
                <label for="supervision">Supervision poll interval</label>
                <b-form-input id="supervision" v-model="siaRemote.supervision" :state="errors.supervision ? false : null" type="range" min="0" max="3600" step="5" />
                <b-badge v-if="!siaRemote.supervision || siaRemote.supervision === '0' || siaRemote.supervision === 0" variant="danger"> Disabled </b-badge>
                <div v-if="siaRemote.supervision && siaRemote.supervision !== '0' && siaRemote.supervision !== 0" class="mt-2">
                  {{ (siaRemote.supervision * 1000) | duration('humanize') }}
                </div>
              </div>

              <div class="text-center">
                <b-button type="submit" variant="success" :disabled="saving"> Save </b-button>
              </div>
            </form>
          </div>
        </b-card>

        <b-card title="Network interfaces" sub-title="Configure network interfaces" class="mb-4">
          <NetworkComponentList id="network-interfaces" />
        </b-card>

        <b-card title="Software update" sub-title="Shows software update info" class="mb-4">
          <div v-if="swUpdateStatus" id="software-update">
            <b-badge v-if="swUpdateStatus.error" variant="warning">
              {{ swUpdateStatus.error }}
            </b-badge>
            <br />
          </div>
          <form @submit.prevent="startSWUpdate">
            <div v-if="swUpdateStatus" class="table-responsive pl-4 pr-4">
              <b-badge v-if="swUpdateStatus.current"> Current {{ swUpdateStatus.current.name }} v{{ swUpdateStatus.current.version || '0.0.0' }}, OS: v{{ swUpdateStatus.currentOSVersion }} </b-badge>
              <br />
              <b-badge v-if="swUpdateStatus.new" variant="success"> Update {{ swUpdateStatus.new.name }} v{{ swUpdateStatus.new.version }} available </b-badge>
              <b-badge v-if="swUpdateStatus.new && swUpdateStatus.new.OS_RELEASE_VERSION" variant="success"> OS: LarvaOS v{{ swUpdateStatus.new.OS_RELEASE_VERSION }} </b-badge>
            </div>
            <div v-if="swUpdateStatus.state">
              State: <strong>{{ swUpdateStatus.state }}</strong> (Next check in {{ swUpdateStatus.nextCheck | duration('humanize') }})
            </div>
            <div class="text-center">
              <b-button v-if="swUpdateStatus && swUpdateStatus.state === 'idle'" variant="primary" style="margin-right: 1em" @click="startCheck()"> Check now </b-button>
              <b-button v-if="updateExists()" type="submit" variant="success"> Update </b-button>
            </div>
          </form>
        </b-card>
        <BenchEnvVariables />
        <ConfBackups />
        <b-card title="Device Management" class="mb-4">
          <b-button-group class="usersbuttons">
            <b-button @click="$bvModal.show('reboot-modal')" :disabled="saving">Reboot</b-button>
          </b-button-group>
          <b-button-group class="usersbuttons">
            <b-button @click="$bvModal.show('reset-modal')" :disabled="saving">Reset configuration</b-button>
          </b-button-group>
        </b-card>
        <b-modal id="reboot-modal" hide-footer hide-header>
          <div class="text-center">
            <span>Are you sure you want to reboot the device?</span>
            <div style="margin-top: 1em">
              <button type="button" class="btn btn-secondary" style="margin-right: 1em" @click="$bvModal.hide('reboot-modal')">Cancel</button>
              <button type="button" class="btn btn-primary" @click="reboot">Reboot</button>
            </div>
          </div>
        </b-modal>
        <b-modal id="reset-modal" hide-footer hide-header>
          <div class="text-center">
            <span>It is recommended to back up your configuration before resetting.</span><br />
            <span>Are you sure you want to reset the configuration?</span>
            <div style="margin-top: 1em">
              <button type="button" class="btn btn-secondary" style="margin-right: 1em" @click="$bvModal.hide('reset-modal')">Cancel</button>
              <button type="button" class="btn btn-primary" @click="resetConfiguration">Reset</button>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import _ from 'lodash';
import DeepDiff from 'deep-diff';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import NetworkComponentList from './NetworkConfig/NetworkConfigList.vue';
import ConfBackups from './ConfigBackups/ConfBackups.vue';
import BenchEnvVariables from './BenchEnvVariables/BenchEnvVariables.vue';
import HiveSettingsFetch from './HiveSettingsFetch/HiveSettingsFetch.vue';

library.add(faClipboard);

export default {
  inject: ['$larva'],
  components: {
    NetworkComponentList,
    ConfBackups,
    BenchEnvVariables,
    HiveSettingsFetch,
  },
  larva: {
    events: {
      data(topic, data) {
        // broadcasted statuses that can allways change
        /* eslint-disable prettier/prettier */
        switch (topic) {
        case 'iot-2/evt/cloudConnection/fmt/json': {
          this.cloudconnection = data.connected;
          break;
        }
        default: {
          //
        }
        }
        /* eslint-enable prettier/prettier */
      },
    },
  },
  data() {
    return {
      siaRemote: {
        servers: [],
        connected: false,
        supervision: 60,
        password: '',
      },
      cloudconfig: {
        enabled: false,
      },
      cloudconnection: false,
      errors: {},
      cloudkey: '',
      deviceInfo: {},
      hwConf: {},
      hwConfModifyable: {},
      confChanged: false,
      swUpdateStatus: {},
      deviceMetadata: {},
      saving: false,
      flowRevision: '',
      larvaServicesIssuer: '',
    };
  },
  created() {
    this._ = _;
    this.start();
  },
  methods: {
    clipboard(el) {
      try {
        if (document.selection) {
          document.selection.empty();
          const range = document.body.createTextRange();
          range.moveToElementText(el.target);
          range.select();
        } else {
          window.getSelection().removeAllRanges();
          const range = document.createRange();
          range.setStartBefore(el.target);
          range.setEndAfter(el.target);
          window.getSelection().addRange(range);
        }
        if (document.execCommand('copy')) {
          this.success('Copied to clipboard');
        }
      } catch (e) {
        console.error(e);
        // suppress error
      }
    },
    confHasChanged() {
      const changes = DeepDiff(this.hwConf, this.hwConfModifyable);
      return changes && changes.length > 0;
    },
    enumOptions(enumValues) {
      return _.map(enumValues, (value) => ({
        value,
        text: value,
      }));
    },
    ioTypeLong(type) {
      /* eslint-disable prettier/prettier */
      switch (type) {
      case 'dos': {
        return 'Digital Outputs';
      }
      case 'dis': {
        return 'Digital Inputs';
      }
      case 'aos': {
        return 'Analog Outputs';
      }
      case 'ais': {
        return 'Analog Inputs';
      }
      default:
        return type;
      }
      /* eslint-enable prettier/prettier */
    },
    err(err) {
      // larva promise error handler
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      } else {
        errormsg = err.message;
      }
      this.$notify({
        title: 'Error',
        text: errormsg || 'Unknown error',
        type: 'error',
      });
    },
    success(message) {
      this.$notify({
        title: 'Done',
        text: message,
        type: 'success',
      });
    },
    async saveCloud() {
      const { cloudconfig } = this;
      this.saving = true;
      try {
        await this.$larva.request('iot-2/cmd/setCloudConfig/fmt/json', cloudconfig);
        this.success('Cloud settings changed');
        await this.getCloudConfig();
      } catch (err) {
        this.err(err);
      } finally {
        this.saving = false;
      }
    },
    async saveHWConf() {
      const changes = DeepDiff(this.hwConf, this.hwConfModifyable);
      const changesObject = {};
      _.forEach(changes, (change) => {
        // set the whole channel object
        const channelPath = change.path.slice(0, change.path.length - 1);
        if (channelPath.length > 0 && typeof channelPath[channelPath.length - 1] === 'number') {
          _.set(changesObject, channelPath.slice(0, channelPath.length - 1), [
            ...(_.get(changesObject, channelPath.slice(0, channelPath.length - 1)) || []),
            _.get(this.hwConfModifyable, channelPath),
          ]);
        } else {
          _.set(changesObject, channelPath, _.get(this.hwConfModifyable, channelPath));
        }
      });
      this.saving = true;
      try {
        await this.$larva.request('iot-2/cmd/setControlConf/fmt/json', changesObject);
        await this.getHWInfo();
        this.success('HW settings changed');
      } catch (err) {
        this.err(err);
      } finally {
        this.saving = false;
      }
    },
    getDeviceMetadata() {
      return this.$larva
        .request('iot-2/cmd/getDeviceMetadata/fmt/json', null)
        .then((data) => {
          this.deviceMetadata = data;
        })
        .catch(this.err);
    },
    async setDeviceMetadata() {
      this.saving = true;
      try {
        await this.$larva.request('iot-2/cmd/setDeviceMetadata/fmt/json', this.deviceMetadata);
        this.success('Device metadata saved');
      } catch (err) {
        this.err(err);
      } finally {
        this.saving = false;
      }
    },
    getDeviceInfo() {
      return this.$larva
        .request('iot-2/cmd/getDeviceInfo/fmt/json', null)
        .then((data) => {
          this.deviceInfo = data;
        })
        .catch(this.err);
    },
    getHWInfo() {
      return this.$larva
        .request('iot-2/cmd/getHwInfo/fmt/json', null)
        .then((data) => {
          this.hwConf = data;
          this.hwConfModifyable = _.cloneDeep(data);
        })
        .catch(this.err);
    },
    getSWUpdateStatus() {
      return this.$larva
        .request('iot-2/cmd/swUpdateStatus/fmt/json', null)
        .then((data) => {
          this.swUpdateStatus = data || {};
          if (this.swUpdateStatus && (this.swUpdateStatus.state === 'swUpdateRunning' || this.swUpdateStatus.state === 'swCheckRunning')) {
            if (!this.checkInterval) {
              this.checkInterval = setInterval(this.getSWUpdateStatus.bind(this), 2000);
            }
          } else {
            clearInterval(this.checkInterval);
            this.checkInterval = null;
          }
        })
        .catch(() => {
          // this.err(err);
          this.swUpdateStatus = {
            error: 'Daemon connection failed',
          };
        });
    },
    updateExists() {
      return this.swUpdateStatus && this.swUpdateStatus.new;
    },
    startSWUpdate() {
      return this.$larva
        .request('iot-2/cmd/swUpdateStart/fmt/json', null)
        .then((data) => {
          if (!data.error) {
            this.$notify({
              title: 'In progress',
              text: 'Software update is in progress...',
              type: 'warning',
            });
          } else {
            this.$notify({
              title: 'Failed to start SW update',
              text: data.error,
              type: 'error',
            });
          }
        })
        .catch(this.err);
    },
    startCheck() {
      return this.$larva
        .request('iot-2/cmd/swUpdateCheck/fmt/json', null)
        .then(() => {
          if (!this.checkInterval) {
            if (this.swUpdateStatus) {
              this.swUpdateStatus.state = 'checking';
            }
            this.checkInterval = setInterval(this.getSWUpdateStatus.bind(this), 2000);
          }
        })
        .catch(this.err);
    },
    addSIAReceiver() {
      if (!this.siaRemote) {
        this.siaRemote = {};
      }
      if (!this.siaRemote.servers || !Array.isArray(this.siaRemote.servers)) {
        this.siaRemote.servers = [];
      }
      this.siaRemote.servers.push({ host: '', port: '' });
      this.$forceUpdate();
    },
    getSIARemoteReportConf() {
      return this.$larva
        .request('iot-2/cmd/getRemoteSIAReportConf/fmt/json', null)
        .then((data) => {
          this.siaRemote = data;
        })
        .catch(this.err);
    },
    getFlowRevision() {
      return this.$larva
        .request('iot-2/cmd/getFlowRevision/fmt/json', null)
        .then((data) => {
          this.flowRevision = data;
        })
        .catch(this.err);
    },
    getLarvaServicesIssuer() {
      return this.$larva
        .request('iot-2/cmd/getLarvaServicesIssuer/fmt/json', null)
        .then((data) => {
          this.larvaServicesIssuer = data;
        })
        .catch(this.err);
    },
    async saveSIARemoteReportConf() {
      this.saving = true;
      try {
        await this.$larva.request('iot-2/cmd/setRemoteSIAReportConf/fmt/json', this.siaRemote);
        this.success('Remote Reporting saved');
        await this.getSIARemoteReportConf();
      } catch (err) {
        this.err(err);
      } finally {
        this.saving = false;
      }
    },
    start() {
      this.$larva
        .request('iot-2/cmd/getCloudConnection/fmt/json', null)
        .then((data) => {
          this.cloudconnection = Boolean(data.connected);
        })
        .catch(this.err);
      this.getCloudConfig();
      this.getHWInfo();
      this.getSIARemoteReportConf();
      this.getSWUpdateStatus();
      this.getDeviceMetadata();
      this.getDeviceInfo();
      this.getFlowRevision();
      this.getLarvaServicesIssuer();
    },
    getCloudConfig() {
      this.$larva
        .request('iot-2/cmd/getCloudConfig/fmt/json', null)
        .then((data) => {
          this.cloudconfig.enabled = data.enabled;
          this.cloudkey = data.cloudkey;
        })
        .catch(this.err);
    },
    async resetConfiguration() {
      this.saving = true;
      try {
        await this.$larva.request('iot-2/cmd/configurationReset/fmt/json', null);
      } catch (err) {
        this.err(err);
      } finally {
        this.saving = false;
        this.$bvModal.hide('reset-modal');
      }
    },
    async reboot() {
      this.saving = true;
      try {
        await this.$larva.request('iot-2/cmd/reboot/fmt/json', null);
      } catch (err) {
        this.err(err);
      } finally {
        this.saving = false;
        this.$bvModal.hide('reboot-modal');
      }
    },
  },
};
</script>
<style lang="scss">
@import './../assets/sass/larva/_variables.scss';
code {
  overflow-x: auto;
  background: $background-color-step-150;
  display: block;
  white-space: pre;
}
</style>

<template>
  <component :is="tag" class="nav-item" v-bind="$attrs">
    <a class="nav-link">
      <slot>
        <fa-icon v-if="icon" :icon="icon" />
        <p>{{ name }}</p>
      </slot>
    </a>
  </component>
</template>
<script>
export default {
  name: 'SidebarLink',
  inheritAttrs: false,
  inject: {
    autoClose: {
      default: true,
    },
    addLink: {
      default: () => {
        //
      },
    },
    removeLink: {
      default: () => {
        //
      },
    },
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    tag: {
      type: String,
      default: 'router-link',
    },
  },
  mounted() {
    if (this.addLink) {
      this.addLink(this);
    }
  },
  beforeDestroy() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
  methods: {
    isActive() {
      return this.$el.classList.contains('active');
    },
  },
};
</script>
<style lang="scss">
.nav-link p {
  display: inline-block;
}
.nav-link svg {
  min-width: 20px;
  margin-right: 10px;
}
</style>

<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="System Users" sub-title="Edit users">
          <b-button-toolbar key-nav class="float-right" aria-label="Button Toolbar">
            <b-button-group class="usersbuttons">
              <router-link :to="{ name: 'app.users.new' }">
                <b-btn variant="success" title="New User">
                  <fa-icon icon="plus" />
                  New User
                </b-btn>
              </router-link>
            </b-button-group>
          </b-button-toolbar>

          <b-table hover :fields="usersFields" :items="deviceUsers">
            <template #cell(updatedAt)="{ item }">
              <span>{{ item.updatedAt | moment('lll') }}</span>
            </template>
            <template #cell(createdAt)="{ item }">
              <span>{{ item.createdAt | moment('lll') }}</span>
            </template>
            <template #cell(cloudId)="{ item }">
              <b-badge v-if="item.cloudId" variant="warning"> {{ item.cloudId }} </b-badge>
            </template>
            <template #cell(role)="{ item }">
              <b-badge v-if="item.role == 'INSTALLER'" variant="danger"> Installer </b-badge>
              <b-badge v-if="item.role == 'SYSMASTER'" variant="warning"> System Master </b-badge>
              <b-badge v-if="item.role == 'MASTER'" variant="info"> Master </b-badge>
              <b-badge v-if="item.role == 'USER'" variant="secondary"> User </b-badge>
            </template>
            <template #cell(actions)="{ item }">
              <router-link title="Edit" :to="{ name: 'app.users.edit', params: { id: item.id } }">
                <fa-icon icon="edit" />
              </router-link>
              <a href="#" title="Remove" class="text-danger ml-2" @click.prevent="removeUser(item)">
                <fa-icon icon="trash" />
              </a>
            </template>
          </b-table>
          <b-pagination :total-rows="deviceUsersCount" :per-page="limit" small @change="getUsers" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

library.add(faEdit, faPlus, faTrash);

export default {
  inject: ['$larva'],
  data() {
    return {
      deviceUsers: [],
      deviceUsersCount: 0,
      limit: 50,
      usersFields: [
        /*
        {
          key: 'id',
          sortable: false,
        },
        */
        {
          key: 'username',
          sortable: false,
        },
        {
          key: 'firstname',
          sortable: false,
        },
        {
          key: 'lastname',
          sortable: false,
        },
        {
          key: 'role',
          sortable: false,
        },
        {
          key: 'cloudId',
          sortable: false,
        },
        {
          key: 'updatedAt',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.getUsers();
  },
  methods: {
    success(title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    err(err) {
      this.$notify({
        title: 'Error',
        text: err.message || 'Unknown error',
        type: 'error',
      });
    },
    getUsers(page) {
      const currentpage = (page || 1) - 1;
      const offset = currentpage * this.limit;
      const { limit } = this;
      this.$larva
        .request('iot-2/cmd/getUsers/fmt/json', { offset, limit })
        .then((data) => {
          this.deviceUsers = data.users;
          this.deviceUsersCount = data.count;
        })
        .catch(this.err);
    },
    removeUser(user) {
      const { id } = user;
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure?') === true) {
        this.$larva
          .request('iot-2/cmd/deleteUser/fmt/json', { id })
          .then(() => {
            this.success('Done', 'User Removed');
            this.getUsers();
          })
          .catch(this.err);
      }
    },
  },
};
</script>

import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { LarvaDevice } from '@larva.io/clouddevice';
import larvaWebComponents from '@larva.io/webcomponents-vue2';
import * as Moment from 'vue-moment';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Notifications from 'vue-notification';
import * as Cookie from 'tiny-cookie';
import dashboard from './plugins/dashboard';
import router from './router/index';
import App from './App.vue';
import { store } from './store/store';

Vue.component('FaIcon', FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(larvaWebComponents);
Vue.use(dashboard);
Vue.use(Moment);
Vue.use(Notifications);

/*
Vue.$larva.interceptors.connect.use((url) => {
  const token = Cookie.get('token');
  const tokenStr = token ? `?token=${token}` : '';
  console.warn('Token', token, tokenStr);
  return `${url}${tokenStr}`;
});
*/

Vue.axios.defaults.baseURL = '/api';

// eslint-disable-next-line no-restricted-globals
const wsurl = `${location.origin.replace(/^https/, 'wss').replace(/^http/, 'ws')}/ws`;

/* eslint-disable no-new */
new Vue({
  router,
  store,
  provide: {
    $larva: new LarvaDevice(undefined, (): string => Cookie.get('token') || '', {
      server: wsurl,
      timeout: 7000,
    }),
  },
  render: (h) => h(App),
}).$mount('#app');


import Component from 'vue-class-component';
import Vue from 'vue';
import moment from 'moment';
import ConnectionStatus from './components/ConnectionStatus.vue';

@Component({
  inject: ['$larva'],
  components: {
    ConnectionStatus,
  },
})
export default class extends Vue {
  public moment = moment;
  public systems = [];
  public systemsCount = 0;
  public limit = 50;
  public systemsFields = [
    /*
    {
      key: 'id',
      sortable: false,
    },
    */
    {
      key: 'name',
      sortable: false,
    },
    {
      key: 'type',
      sortable: false,
    },
    {
      key: 'data',
      label: 'Config',
      sortable: false,
    },
    {
      key: 'createdAt',
      sortable: false,
    },
    {
      key: 'updatedAt',
      sortable: false,
    },
    {
      key: 'status',
      sortable: false,
    },
    {
      key: 'actions',
      sortable: false,
    },
  ];

  public created() {
    this.getSystems();
  }

  public getSystems(page = 1) {
    const currentpage = page - 1;
    const { limit } = this;
    const offset = currentpage * limit;
    this.$larva
      .request('iot-2/cmd/getTechnicalConnections/fmt/json', { offset, limit })
      .then((data) => {
        this.systems = data.connections;
        this.systemsCount = data.count;
      })
      .catch(this.err);
  }

  public removeSystem(id: string) {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Are you sure?') !== true) {
      return;
    }
    this.$larva
      .request('iot-2/cmd/deleteTechnicalConnection/fmt/json', { id })
      .then(() => {
        this.getSystems();
      })
      .catch(this.err);
  }

  private err(err) {
    this.$notify({
      title: 'Error',
      text: err.message || 'Unknown error',
      type: 'error',
    });
  }
}

<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Units" sub-title="Edit units">
          <b-button-toolbar key-nav class="float-right" aria-label="Button Toolbar">
            <b-button-group class="usersbuttons">
              <router-link :to="{ name: 'app.units.new' }">
                <b-btn variant="success" title="New Unit"> <fa-icon icon="plus" /> New Unit </b-btn>
              </router-link>
            </b-button-group>
          </b-button-toolbar>
          <b-table hover :fields="unitFields" :items="units">
            <template #cell(name)="{ item }">
              <router-link :to="{ name: 'app.units.view', params: { id: item.id } }">
                {{ item.name }}
              </router-link>
            </template>
            <template #cell(actions)="{ item }">
              <b-badge v-if="item.cloud" variant="info"> Cloud Unit </b-badge>
              <router-link v-if="!item.cloud" title="Edit" :to="{ name: 'app.units.edit', params: { id: item.id } }">
                <fa-icon icon="edit" />
              </router-link>
              <a v-if="!item.cloud && item.id !== DEFAULT_UNIT" href="#" title="Remove" class="text-danger ml-2" @click.prevent="removeUnit(item)">
                <fa-icon icon="trash" />
              </a>
            </template>
          </b-table>
          <b-pagination :total-rows="deviceUnitsCount" :per-page="limit" small @change="getUnits" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

library.add(faEdit, faPlus, faTrash);

export const DEFAULT_UNIT = '00000000-0000-0000-0000-000000000000';

export default {
  inject: ['$larva'],
  data() {
    return {
      units: [],
      deviceUnitsCount: 0,
      limit: 50,
      unitFields: [
        /*
        {
          key: 'id',
          sortable: false,
        },
        */
        {
          key: 'name',
          sortable: false,
        },
        {
          key: 'actions',
          sortable: false,
        },
      ],
      DEFAULT_UNIT,
    };
  },
  created() {
    this.getUnits();
  },
  methods: {
    success(title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    err(err) {
      this.$notify({
        title: 'Error',
        text: err.message || 'Unknown error',
        type: 'error',
      });
    },
    getUnits(page) {
      const currentpage = (page || 1) - 1;
      const offset = currentpage * this.limit;
      const { limit } = this;

      this.$larva
        .request('iot-2/cmd/getUnits/fmt/json', { offset, limit })
        .then((rsp) => {
          this.units = rsp.units;
          this.deviceUnitsCount = rsp.count;
        })
        .catch(this.err);
    },
    removeUnit(unit) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure?') === true) {
        this.$larva
          .request('iot-2/cmd/deleteUnit/fmt/json', { id: unit.id })
          .then(() => {
            this.success('Done', 'Unit Removed');
            this.getUnits();
          })
          .catch(this.err);
      }
    },
  },
};
</script>

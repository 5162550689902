
/* eslint-disable no-undef */
import * as Cookie from 'tiny-cookie';
import Component from 'vue-class-component';
import Vue from 'vue';
import { LarvaError, LarvaEvent, LarvaEventListener } from '@larva.io/clouddevice';

@Component({
  inject: ['$larva'],
  name: 'App',
})
export default class extends Vue {
  async created() {
    // TODO: check Token TokenExpiredError using jwt
    this.$larva.addEventListener('error', this.connectionError.bind(this) as LarvaEventListener);
    try {
      const token = Cookie.get('token');
      const unit = Cookie.get('unit');
      if (token && unit) {
        this.$larva.setUnit(unit);
      }
      if (!token && this.$router.currentRoute.name !== 'login') {
        this.$router.replace({ name: 'login' });
      } else if (token && this.$router.currentRoute.name !== 'login') {
        // we have token, lets open socket for dashboard
        await this.$larva.open();
      }
    } catch (err) {
      this.err(err);
    }
  }
  err(err) {
    this.$notify({
      title: 'Error',
      text: err.message || 'Unknown error',
      type: 'error',
    });
  }
  connectionError(ev: LarvaEvent<LarvaError>) {
    if (ev.detail?.statusCode === 401) {
      if (this.$route.name !== 'login') {
        this.$router.replace({ name: 'login' });
      }
    }
  }
}

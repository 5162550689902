<template>
  <b-modal id="sw-update-modal" hide-footer hide-header>
    <div class="text-center">
      <span>A software update exist. Do you want to start the update?</span>
      <div style="margin-top: 1em">
        <button type="button" class="btn btn-secondary" style="margin-right: 1em" @click="closeModal">Not now</button>
        <button type="button" class="btn btn-primary" @click="startUpdate">Update</button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import * as Cookie from 'tiny-cookie';

export default {
  name: 'SwUpdateComponent',
  inject: ['$larva'],
  data: () => ({
    swUpdateDismissed: false,
  }),
  mounted() {
    const unit = Cookie.get('unit');
    if (!unit) {
      this.getSWUpdateStatus();
    }
  },
  methods: {
    getSWUpdateStatus() {
      return this.$larva
        .request('iot-2/cmd/swUpdateStatus/fmt/json', null)
        .then((data) => {
          if (data.new) {
            this.$bvModal.show('sw-update-modal');
          }
        })
        .catch(() => {
          // suppress the error
        });
    },
    startUpdate() {
      this.$larva
        .request('iot-2/cmd/swUpdateStart/fmt/json')
        .then((data) => {
          if (!data.error) {
            this.$notify({
              title: 'In progress',
              text: 'Software update is in progress...',
              type: 'warning',
            });
          } else {
            this.$notify({
              title: 'Failed to start SW update',
              text: data.error,
              type: 'error',
            });
          }
          this.closeModal();
        })
        .catch(this.err);
    },
    closeModal() {
      this.$bvModal.hide('sw-update-modal');
      this.swUpdateDismissed = true;
    },
  },
};
</script>
<style lang="scss"></style>

<template>
  <div v-if="hwConf && securityConf">
    <b-row>
      <b-col>
        <b-card title="Areas" sub-title="Edit areas">
          <form @submit.prevent="save">
            <b-button-toolbar key-nav class="float-right" aria-label="Button Toolbar">
              <b-button-group class="usersbuttons">
                <b-btn id="new_area_btn" variant="success" title="New Area" @click="addArea()"> <fa-icon icon="plus" /> New Area </b-btn>
              </b-button-group>
            </b-button-toolbar>
            <b-table id="security_table" hover :fields="areaFields" :items="securityConfModifyable">
              <template #cell(actions)="row">
                <a href="#" title="Edit" @click.prevent="row.toggleDetails">
                  <fa-icon icon="edit" />
                </a>
                <a href="#" title="Remove" class="text-danger ml-2" @click.prevent="removeArea(row.item)">
                  <fa-icon icon="trash" />
                </a>
              </template>
              <template #row-details="{ item: area }">
                <b-card>
                  <b-container>
                    <b-form-group label="Name">
                      <b-form-input v-model="area.name" type="text" placeholder="Name" />
                    </b-form-group>
                    <b-form-group label="Area ID (ContactID)">
                      <b-form-input v-model="area.area_contact_id" type="text" placeholder="Two digit hex" />
                    </b-form-group>
                    <b-form-group label="Exit/Entry delay">
                      <b-form-input v-model.number="area.transition_delay" type="number" placeholder="Seconds" />
                    </b-form-group>
                    <b-button-group class="usersbuttons float-right">
                      <b-btn size="sm" variant="success" title="New Zone" @click="addZone(area)"> <fa-icon icon="plus" /> New Zone </b-btn>
                    </b-button-group>
                    <b-table hover small :fields="zoneFields" :items="area.zones" show-empty>
                      <template #cell(io)="{ item }">
                        <b-form-select v-model="item.io">
                          <optgroup label="Device">
                            <!-- TODO: we must have item.sn nr for extensions --->
                            <option v-for="zone in hwConf.ios.general.zones" :key="zone.io" :value="zone.io">
                              {{ zone.io }}
                            </option>
                          </optgroup>
                        </b-form-select>
                      </template>
                      <template #cell(type)="{ item }">
                        <b-form-select v-model="item.type" @change="updateZoneType(item, $event)">
                          <optgroup v-for="(types, group) in zoneTypes" :key="group" :label="group">
                            <option v-for="type in types" :key="type.type" :value="type.type">
                              {{ type.label }}
                            </option>
                          </optgroup>
                        </b-form-select>
                      </template>
                      <template #cell(name)="{ item }">
                        <b-form-input v-model="item.name" />
                      </template>
                      <template #cell(group)="{ item }">
                        <b-form-input v-model="item.group" />
                      </template>
                      <template #cell(transition_delay)="{ item }">
                        <b-form-checkbox v-model="item.transition_delay" :value="true" :unchecked-value="false" />
                      </template>
                      <template #cell(actions)="row">
                        <a href="#" title="Remove" class="text-danger" @click.prevent="removeZone(area, row.item)">
                          <fa-icon icon="trash" />
                        </a>
                      </template>
                      <template #empty>
                        <span>No zones configured</span>
                      </template>
                    </b-table>
                    <template v-if="pgms.length">
                      <b-button-group class="usersbuttons float-right">
                        <b-btn size="sm" variant="success" :disabled="availablePgms.length === 0" @click="addPgm(area)"> <fa-icon icon="plus" /> New PGM trigger </b-btn>
                      </b-button-group>
                      <b-table hover small :fields="pgmFields" :items="area.pgms" show-empty>
                        <template #cell(io)="{ item }">
                          <b-form-select v-model="item.io">
                            <option v-for="pgm in pgms" :key="pgm.io" :value="pgm.io">
                              {{ pgm.io }}
                            </option>
                          </b-form-select>
                        </template>
                        <template #cell(actions)="row">
                          <a href="#" title="Remove" class="text-danger" @click.prevent="removePgm(area, row.item)">
                            <fa-icon icon="trash" />
                          </a>
                        </template>
                        <template #cell(trigger_type)="{ item: pgm }">
                          <b-form-group>
                            <b-form-radio v-for="trigger in triggerTypes" :key="trigger" v-model="pgm.trigger_type" :value="trigger">
                              {{ triggerTypesLabels[trigger] }}
                            </b-form-radio>
                          </b-form-group>
                        </template>
                        <template #empty> No PGMs configured </template>
                      </b-table>
                    </template>
                  </b-container>
                </b-card>
              </template>
            </b-table>
            <div class="text-center">
              <b-button type="submit" variant="success"> Save </b-button>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import _ from 'lodash';
import { v4 } from 'uuid';
import ZoneTypes from '../libs/zone-types';

const TriggerTypes = ['alarm', 'arm'];
const TriggerTypeLabels = {
  alarm: 'Trigger ON: alarm ON, Trigger OFF: restored',
  arm: 'Trigger ON: armed, Trigger OFF: disarmed',
};

export default {
  inject: ['$larva'],
  data() {
    return {
      zoneTypes: [],
      hwConf: null,
      securityConf: null,
      securityConfModifyable: null,
      zoneFields: [
        {
          key: 'name',
        },
        {
          key: 'group',
        },
        {
          key: 'io',
          label: 'Zone input',
        },
        {
          key: 'type',
        },
        {
          key: 'transition_delay',
          label: 'Exit/Entry route',
        },
        {
          key: 'actions',
        },
      ],
      areaFields: [
        {
          key: 'name',
        },
        {
          key: 'actions',
        },
      ],
      pgmFields: [{ key: 'io', label: 'PGM' }, { key: 'trigger_type' }, { key: 'normally_open' }, { key: 'actions' }],
    };
  },
  computed: {
    pgms() {
      return this.hwConf?.ios?.general?.pgms || [];
    },
    configuredPgms() {
      const configuredPgms = [];
      if (Array.isArray(this.securityConfModifyable)) {
        for (const area of this.securityConfModifyable) {
          if (Array.isArray(area.pgms)) {
            for (const pgm of area.pgms) {
              configuredPgms.push(pgm);
            }
          }
        }
      }
      return configuredPgms;
    },
    availablePgms() {
      const configuredPgms = this.configuredPgms;
      const availablePgms = [];
      for (const pgm of this.pgms) {
        if (!configuredPgms.find((p) => p.io === pgm.io)) {
          availablePgms.push(pgm);
        }
      }
      return availablePgms;
    },
    triggerTypes() {
      return TriggerTypes;
    },
    triggerTypesLabels() {
      return TriggerTypeLabels;
    },
  },
  async created() {
    this.zoneTypes = _.groupBy(ZoneTypes, 'group');
    try {
      await this.getHWInfo();
      await this.getSecurity();
    } catch (err) {
      this.err(err);
    }
  },

  methods: {
    getSecurity() {
      return this.$larva.request('iot-2/cmd/getSecurity/fmt/json').then((data) => {
        this.securityConf = data.areas || [];
        if (!_.isArray(this.securityConf)) {
          this.securityConf = [];
        }
        this.securityConfModifyable = _.cloneDeep(this.securityConf).map((area) => {
          return {
            ...area,
            pgms: (area.pgms || []).map((pgm) => {
              return {
                ...(this.pgms.find((p) => p.io === pgm.io) || {}),
                ...pgm,
              };
            }),
            // eslint-disable-next-line camelcase
            transition_delay: area.transition_delay || 0,
          };
        });
      });
    },
    getHWInfo() {
      return this.$larva.request('iot-2/cmd/getHwInfo/fmt/json').then((data) => {
        if (!_.has(data, 'ios.general.zones')) {
          throw new Error('Invalid Hardware Configuration received');
        }
        this.hwConf = data;
      });
    },
    addArea() {
      this.securityConfModifyable.push({
        name: `Area ${this.securityConfModifyable.length + 1}`,
        id: v4(),
        // eslint-disable-next-line camelcase
        transition_delay: 0,
        zones: [],
        pgms: [],
      });
    },
    removeZone(area, zone) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure?') !== true) {
        return;
      }
      const index = area.zones.indexOf(zone);
      if (index > -1) {
        area.zones.splice(index, 1);
      }
    },
    removePgm(area, pgm) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure?') !== true) {
        return;
      }
      const index = area.pgms.indexOf(pgm);
      if (index > -1) {
        area.pgms.splice(index, 1);
      }
    },
    removeArea(item) {
      // eslint-disable-next-line no-restricted-globals, no-alert
      if (confirm('Are you sure?') !== true) {
        return;
      }
      const index = this.securityConfModifyable.indexOf(item);
      if (index > -1) {
        this.securityConfModifyable.splice(index, 1);
      }
    },
    addZone(area) {
      // eslint-disable-next-line no-param-reassign
      area.zones = area.zones || [];
      area.zones.push({
        id: v4(),
        name: `Zone ${area.zones.length + 1}`,
        type: ZoneTypes[0].type,
        persistent: ZoneTypes[0].persistent,
        reportcode: ZoneTypes[0].reportcode,
      });
    },
    addPgm(area) {
      // eslint-disable-next-line no-param-reassign
      area.pgms = area.pgms || [];
      if (this.availablePgms.length === 0) {
        this.err(new Error(`Cannot configure single pgm to multiple areas`));
        return;
      }
      area.pgms.push({
        ...this.availablePgms[0],
        // eslint-disable-next-line camelcase
        trigger_type: TriggerTypes[0],
      });
    },
    async save() {
      try {
        await this.$larva.request('iot-2/cmd/setSecurity/fmt/json', {
          areas: this.securityConfModifyable.map((area) => ({
            ...area,
            state: undefined,
            zones: area.zones ? area.zones.map((z) => ({ ...z, state: undefined })) : [],
            // eslint-disable-next-line camelcase
            ...(area.pgms ? { pgms: area.pgms.map((p) => ({ io: p.io, trigger_type: p.trigger_type })) } : undefined),
            _showDetails: undefined,
          })),
        });
        await this.getSecurity();
        this.success('Saved');
      } catch (err) {
        this.err(err);
      }
    },
    err(err) {
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      } else {
        errormsg = err.message;
      }
      this.$notify({
        title: 'Error',
        text: errormsg || 'Unknown error',
        type: 'error',
      });
    },
    success(message) {
      this.$notify({
        title: 'Done',
        text: message,
        type: 'success',
      });
    },
  },
};
</script>
<style></style>

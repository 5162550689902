
import Component from 'vue-class-component';
import Vue from 'vue';

@Component({
  inject: ['$larva'],
})
export default class extends Vue {
  public placeholder = {
    formSubTitle: '',
  };
  public edit = false;
  public datapoint = {
    name: '',
    data: '{}',
    alarmData: '',
    accessType: 'READ',
    analyticsInterval: 0,
    writeInterval: 0,
    writeInputType: 'switch',
    writeInputData: '',
  };
  public types = [
    { value: 'READ', text: 'Read only' },
    { value: 'READ_WRITE', text: 'Read/Write' },
    { value: 'WRITE', text: 'Write only' },
  ];
  public inputs = [
    { value: 'switch', text: 'Switch input' },
    { value: 'number', text: 'Number input' },
    { value: 'radio', text: 'Radio input' },
    { value: 'select', text: 'Select input' },
  ];

  private errors = [];

  public created() {
    const { id, technicalSystemId } = this;
    if (id && technicalSystemId) {
      this.getDatapoint(technicalSystemId, id);
      this.edit = true;
      this.placeholder.formSubTitle = 'Edit Technical System';
    } else {
      this.edit = false;
      this.placeholder.formSubTitle = 'New Technical System';
    }
  }

  public get technicalSystemId() {
    const { technicalSystemId } = this.$route.params;
    return technicalSystemId;
  }

  public get id() {
    const { id } = this.$route.params;
    return id;
  }

  private getDatapoint(connectionId: string, id: string) {
    this.$larva
      .request('iot-2/cmd/getTechnicalDataPoint/fmt/json', { connectionId, id })
      .then((res) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { data, writeInputData, alarmData, updatedAt, createdAt, ...datapoint } = res.datapoint;
        this.datapoint = {
          ...datapoint,
          data: JSON.stringify(data || {}),
          writeInputData: JSON.stringify(writeInputData || {}),
          alarmData: JSON.stringify(alarmData || ''),
        };
      })
      .catch(this.err);
  }

  public save() {
    const { id, technicalSystemId } = this;
    const topic = id && technicalSystemId ? 'iot-2/cmd/editTechnicalDataPoint/fmt/json' : 'iot-2/cmd/createTechnicalDataPoint/fmt/json';
    const { data, writeInputData, alarmData, ...rest } = this.datapoint;
    try {
      const req = {
        ...(id ? { id } : {}),
        ...(technicalSystemId ? { connectionId: technicalSystemId } : {}),
        ...rest,
        data: JSON.parse(data || '{}'),
        writeInputData: JSON.parse(writeInputData || '{}'),
        alarmData: JSON.parse(alarmData || 'null'),
      };
      this.$larva
        .request(topic, req)
        .then(() => {
          this.success('Done', 'Saved');
          this.$router.replace({ name: 'app.technicalconnections.view', params: { id: this.technicalSystemId } });
        })
        .catch(this.err);
    } catch (err) {
      this.err(err);
    }
  }

  public state(e) {
    return this.errors[e] ? false : null;
  }

  private success(title, msg) {
    this.$notify({
      title,
      text: msg,
      type: 'success',
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private err(err: any) {
    console.error(err);
    let errormsg: string | null = null;
    if (err && err.message === 'ValidationError') {
      this.errors = err.details || {};
      errormsg = 'Validation Error';
    }
    this.$notify({
      title: 'Error',
      text: errormsg || err.message || 'Unknown error',
      type: 'error',
    });
  }
}

<template>
  <div>
    <b-row>
      <b-col>
        <b-card title="Unit" :sub-title="placeholder.formSubTitle">
          <form @submit.prevent="save">
            <div class="form-group">
              <label for="name">name</label>
              <b-form-input id="name" v-model="unit.name" :state="errors.name ? false : null" type="text" placeholder="name" />
            </div>

            <div class="text-center">
              <router-link :to="{ name: 'app.units' }">
                <b-button variant="secondary"> Cancel </b-button>
              </router-link>
              <b-button type="submit" variant="success" class="ml-2"> Save </b-button>
            </div>
          </form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  inject: ['$larva'],
  data() {
    return {
      unit: {
        name: '',
      },
      placeholder: {
        formSubTitle: '',
      },
      errors: {
        card: false,
      },
    };
  },
  created() {
    const { id } = this.$route.params;
    if (id) {
      this.getUnits(id);
      this.placeholder.title = 'Edit Unit';
    } else {
      this.placeholder.title = 'New Unit';
    }
  },
  methods: {
    err(err) {
      // larva promise error handler
      this.$notify({
        title: 'Error',
        text: err.message || 'Unknown error',
        type: 'error',
      });
    },
    success(title, msg) {
      this.$notify({
        title,
        text: msg,
        type: 'success',
      });
    },
    getUnits(id) {
      this.$larva
        .request('iot-2/cmd/getUnit/fmt/json', { id })
        .then((rsp) => {
          this.unit = rsp.unit;
        })
        .catch(this.err);
    },
    save() {
      const { id } = this.$route.params;
      const topic = id ? 'iot-2/cmd/editUnit/fmt/json' : 'iot-2/cmd/createUnit/fmt/json';
      /* eslint-disable prettier/prettier */
      const unit = id
        ? {
          id,
          name: this.unit.name,
        }
        : this.unit;
      /* eslint-enable prettier/prettier */
      this.$larva
        .request(topic, unit)
        .then(() => {
          this.success('Done', 'Unit Saved');
          this.$router.go(-1);
        })
        .catch(this.err);
    },
  },
};
</script>

<template>
  <b-card title="Bench environment variables" sub-title="Use JSON" class="mb-4">
    <b-alert variant="warning" :show="cloudFetchsettings.envVariables">Settings managed by larva.io cloud</b-alert>
    <b-form-textarea rows="6" v-model="variables" :disabled="!variables || cloudFetchsettings.envVariables"></b-form-textarea>
    <div class="text-center mt-2">
      <b-btn id="save_btn" variant="success" title="Save ENV variables" :disabled="!variables || cloudFetchsettings.envVariables" @click="setVariables()"> Save </b-btn>
    </div>
  </b-card>
</template>

<script>
// TODO: nice editor
export default {
  inject: ['$larva'],
  data() {
    return {
      variables: null,
      cloudFetchsettings: {},
    };
  },
  async mounted() {
    await this.getHiveSettings();
    await this.getVariables();
  },
  methods: {
    async getHiveSettings() {
      try {
        const res = await this.$larva.request('iot-2/cmd/getHiveFetchSettings/fmt/json', null);
        this.cloudFetchsettings = res;
      } catch (err) {
        this.err(err);
      }
    },
    async getVariables() {
      try {
        const res = await this.$larva.request('iot-2/cmd/getBenchEnvVariables/fmt/json', null);
        this.variables = JSON.stringify(res.variables);
      } catch (err) {
        this.err(err);
      }
    },
    async setVariables() {
      try {
        const confirm = await this.$bvModal.msgBoxConfirm('Bench will be restarted. Are you sure?');
        console.warn(confirm);
        if (confirm) {
          await this.$larva.request('iot-2/cmd/setBenchEnvVariables/fmt/json', { variables: JSON.parse(this.variables) });
          this.success('Saved');
        }
      } catch (err) {
        this.err(err);
      }
    },
    err(err) {
      // larva promise error handler
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      } else {
        errormsg = err.message;
      }
      this.$notify({
        title: 'Error',
        text: errormsg || 'Unknown error',
        type: 'error',
      });
    },
    success(message) {
      this.$notify({
        title: 'Done',
        text: message,
        type: 'success',
      });
    },
  },
};
</script>

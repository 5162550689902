<template>
  <div class="row">
    <div class="col-12">
      <b-card title="Log" sub-title="Event log">
        <b-table hover :fields="logsFields" :items="deviceLogs">
          <template v-slot:cell(createdAt)="{ item }">
            <span>{{ item.createdAt | moment('lll') }}</span>
          </template>
          <template v-slot:cell(id)="{ item }">
            <span
              class="badge"
              :class="{ 'badge-danger': item.level === 'ERROR', 'badge-warning': item.level === 'WARNING', 'badge-info': item.level === 'INFO', 'badge-secondary': item.level === 'DEBUG' }"
            >
              {{ item.id }}
            </span>
          </template>
          <template v-slot:cell(qualify)="{ item }">
            <span v-if="item.qualify === 0x1" class="badge badge-warning"> New </span>
            <span v-if="item.qualify === 0x6" class="badge badge-secondary"> Restore </span>
          </template>
          <template v-slot:cell(data)="{ item }">
            <code style="font-size: 0.7em; line-height: 1.3em">{{ item.data }}</code>
          </template>
        </b-table>
        <b-pagination :total-rows="deviceLogsCount" :per-page="limit" small @change="getLogs" />
      </b-card>
    </div>
  </div>
</template>

<style lang="scss"></style>

<script>
import { getEventMessage, getLogLevel } from '@larva.io/report-definitions';
// TODO: pagination, sorting and refresh;
// TODO: data time parse
export default {
  inject: ['$larva'],
  data() {
    return {
      deviceLogs: [],
      deviceLogsCount: 0,
      limit: 50,
      logsFields: [
        {
          key: 'id',
          sortable: false,
        },
        {
          key: 'qualify',
          sortable: false,
        },
        {
          key: 'code',
          sortable: false,
        },
        {
          key: 'createdAt',
          sortable: false,
        },
        {
          key: 'nodeId',
          sortable: false,
        },
        {
          key: 'message',
          sortable: false,
        },
        {
          key: 'data',
          sortable: false,
        },
      ],
    };
  },
  created() {
    this.getLogs();
  },
  methods: {
    err(err) {
      this.$notify({
        title: 'Error',
        text: err.message || 'Unknown error',
        type: 'error',
      });
    },
    getLogs(page) {
      const currentpage = (page || 1) - 1;
      const offset = currentpage * this.limit;
      const { limit } = this;
      this.$larva
        .request('iot-2/cmd/getLogs/fmt/json', { offset, limit })
        .then((data) => {
          this.deviceLogs = (data.logs || []).map((log) => ({
            ...log,
            message: getEventMessage(log.code),
            level: getLogLevel(log.code),
          }));
          this.deviceLogsCount = data.count;
        })
        .catch(this.err);
    },
  },
};
</script>
<style></style>


import Component from 'vue-class-component';
import Vue from 'vue';
import moment from 'moment';
import DatapointValue from './components/DatapointValue.vue';

@Component({
  inject: ['$larva'],
  components: {
    DatapointValue,
  },
})
export default class extends Vue {
  public moment = moment;
  public datapoints = [];
  public datapointsCount = 0;
  public limit = 50;
  public datapointFields = [
    /*
    {
      key: 'id',
      sortable: false,
    },
    */
    {
      key: 'name',
      sortable: false,
    },
    {
      key: 'accessType',
      sortable: false,
    },
    {
      key: 'analyticsInterval',
      label: 'Analytic upload interval',
      sortable: false,
    },
    {
      key: 'writeInterval',
      label: 'Write interval',
      sortable: false,
    },
    {
      key: 'writeInputType',
      label: 'Write UI Input type',
      sortable: false,
    },
    {
      key: 'writeInputData',
      label: 'Write UI Input config',
      sortable: false,
    },
    {
      key: 'data',
      label: 'Config',
      sortable: false,
    },
    {
      key: 'alarmData',
      label: 'Alarm Config',
      sortable: false,
    },
    {
      key: 'createdAt',
      sortable: false,
    },
    {
      key: 'updatedAt',
      sortable: false,
    },
    {
      key: 'value',
      sortable: false,
    },
    {
      key: 'actions',
      sortable: false,
    },
  ];

  public get technicalSystemId() {
    const { id } = this.$route.params;
    return id;
  }

  public mounted() {
    this.getDatapoints();
  }

  public getDatapoints(page = 1) {
    const currentpage = page - 1;
    const { limit } = this;
    const offset = currentpage * limit;
    this.$larva
      .request('iot-2/cmd/getTechnicalDataPoints/fmt/json', { connectionId: this.technicalSystemId, offset, limit })
      .then((data) => {
        this.datapoints = data.datapoints;
        this.datapointsCount = data.count;
      })
      .catch(this.err);
  }

  public editDatapointValue(connectionId: string, id: string) {
    try {
      // eslint-disable-next-line no-alert
      const valueStr = prompt('Please enter numeric value');
      if (!valueStr) {
        return;
      }
      const value = parseFloat(valueStr);
      if (Number.isNaN(value)) {
        throw new Error('Invalid number');
      }
      this.$larva
        .request('iot-2/cmd/setTechnicalDataPointValue/fmt/json', { connectionId, id, value })
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        .then((res) => {
          this.success('Written');
        })
        .catch(this.err);
    } catch (err) {
      this.err(err);
    }
  }

  public removeDatapoint(connectionId: string, id: string) {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (confirm('Are you sure?') !== true) {
      return;
    }
    this.$larva
      .request('iot-2/cmd/deleteTechnicalDataPoint/fmt/json', { id, connectionId })
      .then(() => {
        this.getDatapoints();
      })
      .catch(this.err);
  }

  private err(err) {
    this.$notify({
      title: 'Error',
      text: err.message || 'Unknown error',
      type: 'error',
    });
  }

  private success(message: string) {
    this.$notify({
      title: 'Done',
      text: message,
      type: 'success',
    });
  }
}

import DashboardLayout from '../layout/dashboard/DashboardLayout.vue';
import MainLayout from '../layout/Main.vue';
// GeneralViews
import NotFound from '../pages/NotFoundPage.vue';

// user pages
import Login from '../pages/Login.vue';
import Logout from '../pages/Logout.vue';

// app pages
import Dashboard from '../pages/Dashboard.vue';
import Bench from '../pages/Bench.vue';
import Security from '../pages/Security.vue';
import Alarms from '../pages/Alarms.vue';
import Log from '../pages/Log.vue';
import Users from '../pages/Users/List.vue';
import UsersForm from '../pages/Users/Form.vue';
import Units from '../pages/Units/List.vue';
import UnitForm from '../pages/Units/UnitForm.vue';
import UnitData from '../pages/Units/UnitData.vue';
import UnitUserForm from '../pages/Units/UserForm.vue';
import UnitCardForm from '../pages/Units/CardForm.vue';
import UnitLicensePlateForm from '../pages/Units/LicensePlateForm.vue';
import Diagnostics from '../pages/Diagnostics.vue';
import TechnicalConnections from '../pages/TechnicalConnections/List.vue';
import TechnicalConnectionsForm from '../pages/TechnicalConnections/Form.vue';
import TechnicalSystemData from '../pages/TechnicalConnections/TechnicalConnectionData.vue';
import TechnicalDataPointForm from '../pages/TechnicalConnections/DatapointForm.vue';
import System from '../pages/System.vue';

const routes = [
  {
    path: '/',
    redirect: '/login',
    component: MainLayout,
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
      },
      {
        path: 'logout',
        name: 'logout',
        component: Logout,
      },
    ],
  },
  {
    path: '/app',
    component: DashboardLayout,
    name: 'app',
    redirect: '/app/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'app.dashboard',
        component: Dashboard,
      },
      {
        path: 'security',
        name: 'app.security',
        component: Security,
      },
      {
        path: 'alarms',
        name: 'app.alarms',
        component: Alarms,
      },
      {
        path: 'bench',
        name: 'app.bench',
        component: Bench,
      },
      {
        path: 'log',
        name: 'app.log',
        component: Log,
      },
      {
        path: 'users',
        name: 'app.users',
        component: Users,
      },
      {
        path: 'users/new',
        name: 'app.users.new',
        component: UsersForm,
      },
      {
        path: 'users/edit/:id',
        name: 'app.users.edit',
        component: UsersForm,
      },
      {
        path: 'units',
        name: 'app.units',
        component: Units,
      },
      {
        path: 'units/new',
        name: 'app.units.new',
        component: UnitForm,
      },
      {
        path: 'units/view/:id',
        name: 'app.units.view',
        component: UnitData,
      },
      {
        path: 'units/view/:unitId/users/new',
        name: 'app.units.users.new',
        component: UnitUserForm,
      },
      {
        path: 'units/view/:unitId/users/edit/:id',
        name: 'app.units.users.edit',
        component: UnitUserForm,
      },
      {
        path: 'units/view/:unitId/plates/new',
        name: 'app.units.plates.new',
        component: UnitLicensePlateForm,
      },
      {
        path: 'units/view/:unitId/plates/edit/:id',
        name: 'app.units.plates.edit',
        component: UnitLicensePlateForm,
      },
      {
        path: 'units/view/:unitId/cards/new',
        name: 'app.units.cards.new',
        component: UnitCardForm,
      },
      {
        path: 'units/view/:unitId/cards/edit/:id',
        name: 'app.units.cards.edit',
        component: UnitCardForm,
      },
      {
        path: 'units/edit/:id',
        name: 'app.units.edit',
        component: UnitForm,
      },
      {
        path: 'diagnostics',
        name: 'app.diagnostics',
        component: Diagnostics,
      },
      {
        path: 'technicalconnections',
        name: 'app.technicalconnections',
        component: TechnicalConnections,
      },
      {
        path: 'technicalconnections/new',
        name: 'app.technicalconnections.new',
        component: TechnicalConnectionsForm,
      },
      {
        path: 'technicalconnections/edit/:id',
        name: 'app.technicalconnections.edit',
        component: TechnicalConnectionsForm,
      },
      {
        path: 'technicalconnections/view/:id',
        name: 'app.technicalconnections.view',
        component: TechnicalSystemData,
      },
      {
        path: 'technicalconnections/view/:technicalSystemId/datapoints/new',
        name: 'app.technicalconnections.datapoints.new',
        component: TechnicalDataPointForm,
      },
      {
        path: 'technicalconnections/view/:technicalSystemId/datapoints/:id',
        name: 'app.technicalconnections.datapoints.edit',
        component: TechnicalDataPointForm,
      },
      {
        path: 'system',
        name: 'app.system',
        component: System,
      },
    ],
  },
  {
    path: '*',
    component: MainLayout,
    children: [
      {
        path: '*',
        component: NotFound,
      },
    ],
  },
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};* */

export default routes;

import { ReportEvent } from '@larva.io/report-definitions';

const groups = {
  INTRUDER: 'Intruder',
  MEDICAL: 'Medical  (24/7)',
  FIRE: 'Fire (24/7)',
  PANIC: 'Panic',
  OTHER: 'Other (24/7)',
};
const ZoneTypes = [
  {
    reportcode: ReportEvent.SECURITY_BURGLARY_ALARM,
    label: 'Movement',
    type: 'movement',
    group: groups.INTRUDER,
    persistent: false,
  },
  {
    reportcode: ReportEvent.SECURITY_PERIMETER_ALARM,
    label: 'Perimeter',
    type: 'perimeter',
    group: groups.INTRUDER,
    persistent: false,
  },
  {
    reportcode: ReportEvent.SECURITY_ENTRY_EXIT_ALARM,
    label: 'Door',
    type: 'door',
    group: groups.INTRUDER,
    persistent: false,
  },
  {
    reportcode: ReportEvent.SECURITY_ENTRY_EXIT_ALARM,
    label: 'Window',
    type: 'window',
    group: groups.INTRUDER,
    persistent: false,
  },
  {
    reportcode: ReportEvent.SECURITY_ENTRY_EXIT_ALARM,
    label: 'Glass',
    type: 'glass',
    group: groups.INTRUDER,
    persistent: false,
  },
  {
    reportcode: ReportEvent.SECURITY_BURGLARY_ALARM,
    label: 'Sound',
    type: 'sound',
    group: groups.INTRUDER,
    persistent: false,
  },
  {
    reportcode: ReportEvent.SECURITY_BURGLARY_ALARM,
    label: 'Vibration',
    type: 'vibration',
    group: groups.INTRUDER,
    persistent: false,
  },
  {
    reportcode: ReportEvent.SECURITY_MEDICAL_ALARM,
    label: 'Medical',
    type: 'medical',
    group: groups.MEDICAL,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_PERSONAL_EMERGENCY,
    label: 'Personal emergency',
    type: 'personal emergency',
    group: groups.MEDICAL,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_FAIL_TO_REPORT_IN,
    label: 'Fail to report in',
    type: 'fail to report in',
    group: groups.MEDICAL,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_FIRE_ALARM,
    label: 'Fire',
    type: 'fire',
    group: groups.FIRE,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_SMOKE_ALARM,
    label: 'Smoke',
    type: 'smoke',
    group: groups.FIRE,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_COMBUSTION_ALARM,
    label: 'Combustion',
    type: 'combustion',
    group: groups.FIRE,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_HEAT_ALARM,
    label: 'Temperature',
    type: 'temperature',
    group: groups.FIRE,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_FLAME_ALARM,
    label: 'Flame',
    type: 'flame',
    group: groups.FIRE,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_PANIC_ALARM,
    label: 'Panic button / holdup',
    type: 'panic',
    group: groups.PANIC,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_24_HOUR_NON_BURGLARY,
    label: '24h Non-burgular',
    type: '24h non-burgular',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_GAS_DETECTED,
    label: 'Gas',
    type: 'gas',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_REFRIGERATION,
    label: 'Refrigeration',
    type: 'refrigeration',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_WATER_LEAKAGE,
    label: 'Water leakage',
    type: 'water',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_FOIL_BREAK,
    label: 'Foil break',
    type: 'foil',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_HIGH_TEMP,
    label: 'Hight temp',
    type: 'hight temp',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_LOW_TEMP,
    label: 'Low temp',
    type: 'low temp',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_LOSS_OF_AIR_FLOW,
    label: 'Airflow (loss)',
    type: 'airflow',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_CARBON_MONOXIDE_DETECTED,
    label: 'Carbon monoxide (CO)',
    type: 'carbonmonoxide',
    group: groups.OTHER,
    persistent: true,
  },
  {
    reportcode: ReportEvent.SECURITY_TANK_LEVEL,
    label: 'Tank level',
    type: 'tank level',
    group: groups.OTHER,
    persistent: true,
  },
];

export default ZoneTypes;

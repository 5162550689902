<template>
  <b-modal v-if="internalItem" id="edit-network-modal" ref="mymodal">
    <template #modal-header>
      <h3 v-if="internalItem.filename">
        {{ internalItem.filename }}
      </h3>
      <h3 v-if="!internalItem.filename">New Interface</h3>
    </template>
    <div v-if="internalItem.config" class="text-center">
      <b-card v-for="(section, sectionName) in sections" :key="sectionName" :title="sectionName">
        <template v-if="internalItem.config[sectionName]">
          <!-- Each section table and its row -->
          <template v-for="keyName in section.keys">
            <template v-if="internalItem.config[sectionName][keyName]">
              <b-table :key="sectionName + '-' + keyName" class="keypair-table no-border" :fields="tableOptions" :items="internalItem.config[sectionName][keyName]">
                <template #cell(key)="{}">
                  {{ keyName }}
                </template>
                <template #cell(value)="{ index }">
                  <b-form-input v-model="internalItem.config[sectionName][keyName][index]" :readonly="true" type="text" placeholder="" />
                </template>
                <template #cell(actions)="{ index }">
                  <a v-if="!(sectionName === 'Match' && internalItem.system)" href="#" title="Remove" class="text-danger" @click.prevent="removeEntry(sectionName, keyName, index)">
                    <fa-icon icon="trash" />
                  </a>
                </template>
              </b-table>
            </template>
          </template>
          <!-- Row for inserting new key-pair to a section -->
          <b-table v-if="!(sectionName === 'Match' && internalItem.system)" :key="sectionName + '-NEW'" class="keypair-table no-border">
            <template slot="bottom-row">
              <td>
                <b-form-select v-model="newFields[sectionName].key" :options="section.keys" @change="newFieldKeyChange($event, sectionName)" />
              </td>
              <td>
                <b-form-input v-if="fields[newFields[sectionName].key].type === 'text'" v-model="newFields[sectionName].value" type="text" placeholder="" />
                <b-form-select v-if="fields[newFields[sectionName].key].type === 'select'" v-model="newFields[sectionName].value" type="text" :options="fields[newFields[sectionName].key].options" />
              </td>
              <td>
                <b-btn variant="success" title="Add entry" @click="addNewFields(sectionName)">
                  <fa-icon icon="plus" />
                </b-btn>
              </td>
            </template>
          </b-table>
        </template>
      </b-card>
    </div>
    <template #modal-footer>
      <b-btn variant="warning" @click="hide"> Close </b-btn>
      <b-btn @click="setNetworkSettings(internalItem)">
        {{ internalItem.index === undefined ? 'Create' : 'Save' }}
      </b-btn>
    </template>
  </b-modal>
</template>
<script>
import * as _ from 'lodash';

export default {
  inject: ['$larva'],
  name: 'EditNetworkConfig',
  data: () => ({
    sections: {
      Match: { keys: ['Name', 'MACAddress'] },
      Network: { keys: ['DHCP', 'Address', 'Gateway', 'DNS', 'NTP'] },
    },
    newFieldsInitial: {
      Match: { key: 'Name', value: '' },
      Network: { key: 'DHCP', value: '' },
    },
    newFields: {
      Match: { key: 'Name', value: '' },
      Network: { key: 'DHCP', value: '' },
    },
    fields: {
      Name: { type: 'text' },
      MACAddress: { type: 'text' },
      DHCP: { type: 'select', options: ['yes', 'no', 'ipv4', 'ipv6'] },
      Address: { type: 'text', default: '192.168.1.1/255.255.255.0' },
      Gateway: { type: 'text', default: '' },
      DNS: { type: 'text' },
      NTP: { type: 'text' },
    },
    tableOptions: [
      {
        key: 'key',
        thStyle: {
          display: 'none',
        },
        tdClass: 'key',
      },
      {
        key: 'value',
        thStyle: {
          display: 'none',
        },
      },
      {
        key: 'actions',
        thStyle: {
          display: 'none',
        },
      },
    ],
    internalItem: {},
    dhcpOptions: [{ key: null, text: '' }, 'yes', 'no', 'ipv4', 'ipv6'],
  }),
  methods: {
    edit(item) {
      this.newFields = _.cloneDeep(this.newFieldsInitial);
      this.internalItem = item;
      this.$refs.mymodal.show();
    },
    new(item) {
      this.edit(item);
    },
    hide() {
      this.$refs.mymodal.hide();
    },
    addEntry(section, key, value) {
      if (!this.internalItem.config[section]) {
        this.internalItem.config[section] = {};
      }
      if (!this.internalItem.config[section][key]) {
        this.internalItem.config[section][key] = [];
      }
      this.internalItem.config[section][key] = [...this.internalItem.config[section][key], value];
      this.internalItem = _.cloneDeep(this.internalItem);
    },
    removeEntry(section, key, index) {
      if (!this.internalItem.config[section] || !this.internalItem.config[section][key] || this.internalItem.config[section][key].length < index + 1) {
        return;
      }
      this.internalItem.config[section][key].splice(index, 1);
    },
    filterConfig(cfg) {
      const newCfg = { filename: cfg.filename, config: {} };
      Object.keys(this.sections).forEach((section) => {
        newCfg.config[section] = {};
        _.forEach(cfg.config[section], (value, key) => {
          if (Array.isArray(value) && value.length > 0) {
            newCfg.config[section][key] = [...value];
          }
        });
      });
      return newCfg;
    },
    combineFields(section) {
      const sections = section ? [section] : Object.keys(this.sections);
      const copy = _.cloneDeep(this.internalItem);
      sections.forEach((s) => {
        if (this.newFields[s].value) {
          // add it to the item
          if (!copy.config[s][this.newFields[s].key]) {
            copy.config[s][this.newFields[s].key] = [];
          }
          copy.config[s][this.newFields[s].key].push(this.newFields[s].value);
        }
      });
      return this.filterConfig(copy);
    },
    async addNewFields(section) {
      const copy = this.combineFields(section);
      await this.validateNetworkConfig(copy, section);
      this.newFields[section] = _.cloneDeep(this.newFieldsInitial[section]);
      this.internalItem = {
        index: this.internalItem.index,
        ...copy,
      };
    },
    async validateNetworkConfig(config, section) {
      try {
        await this.$larva.request('iot-2/cmd/validateNetworkConfig/fmt/json', { configs: [config], section });
      } catch (err) {
        this.err(err);
        throw err;
      }
    },
    async setNetworkSettings(item) {
      try {
        const index = item.index;
        const copy = this.combineFields();
        await this.validateNetworkConfig(copy);
        // eslint-disable-next-line vue/custom-event-name-casing
        this.$emit('saveDone', { ...copy, index });
        this.newFields = _.cloneDeep(this.newFieldsInitial);
      } catch (err) {
        // dismiss, error shown within method calls
      }
    },
    newFieldKeyChange(key, section) {
      this.newFields[section].value = this.fields[key].default;
    },
    err(err) {
      // larva promise error handler
      let errormsg;
      if (err.message === 'ValidationError') {
        this.errors = err.details || {};
        errormsg = 'Validation Error';
      } else {
        errormsg = err.message;
      }
      this.$notify({
        title: 'Error',
        text: errormsg || 'Unknown error',
        type: 'error',
      });
    },
    success(message) {
      this.$notify({
        title: 'Done',
        text: message,
        type: 'success',
      });
    },
  },
};
</script>
<style lang="scss">
.keypair-table td.key {
  min-width: 8em;
}
.keypair-table.table th,
.keypair-table.table td {
  vertical-align: middle;
  border-width: 0;
  padding: 0.3em 0.3em 0 0;
}
</style>

<template>
  <div />
</template>
<script>
import * as Cookie from 'tiny-cookie';

export default {
  name: 'Logout',
  inject: ['$larva'],
  mounted() {
    Cookie.remove('token');
    Cookie.remove('unit');
    this.$larva
      .reconnect()
      .then(() => {
        this.$router.replace({ name: 'login' });
      })
      .catch((err) => {
        console.error(err);
        this.$router.replace({ name: 'login' });
      });
  },
};
</script>

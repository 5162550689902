
/* eslint-disable no-undef */
import Component from 'vue-class-component';
import Vue from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faHeart, faCode } from '@fortawesome/free-solid-svg-icons';
import { LarvaError, LarvaEvent, LarvaEventListener, LarvaMessage } from '@larva.io/clouddevice';

library.add(faHeart, faCode);

@Component({
  inject: ['$larva'],
})
export default class extends Vue {
  created() {
    this.handleBroadcast = this.handleBroadcast.bind(this);
    this.handleError = this.handleError.bind(this);
    this.$larva.addEventListener('broadcast', this.handleBroadcast as LarvaEventListener);
    this.$larva.addEventListener('error', this.handleError as LarvaEventListener);
  }
  beforeDestroy() {
    this.$larva.removeEventListener('broadcast', this.handleBroadcast as LarvaEventListener);
    this.$larva.removeEventListener('error', this.handleError as LarvaEventListener);
  }

  handleBroadcast(ev: LarvaEvent<LarvaMessage>) {
    if (ev.detail.topic === 'iot-2/evt/errors/fmt/json') {
      this.error('Error', ev.detail.payload.err?.message || 'Controller connection error');
    }
  }
  handleError(ev: LarvaEvent<LarvaError>) {
    if (ev.detail.statusCode === 401) {
      this.error('Error', 'Login session timed out');
      this.$router.replace({ name: 'logout' });
    } else if (ev.detail.message) {
      this.error('Error', ev.detail.message);
    }
  }
  error(title: string, msg: string) {
    this.$notify({
      title,
      text: msg,
      type: 'error',
    });
  }
}
